import styled from 'styled-components';
import {
    greyLight,
    black,
    lightGray,
    darkGreen,
    lightGreyButton,
    lightGraishButton,
} from '../../../../styles/Variables';
import { Link } from 'gatsby';

export const Container = styled.div`
    display: grid;
    grid-template-rows: 1fr 2.8fr 1.4fr;
    height: 100%;
`;

export const TextWrapper = styled.div`
    background-color: ${greyLight};
    height: 100%;
    display: flex;
    align-items: center;
`;

export const Text = styled.div`
    padding-left: 1.5rem;
    text-transform: uppercase;
    color: ${black};
    font-weight: 700;
    font-size: 15px;

    * {
        padding-left: 1.5rem;
        text-transform: uppercase;
        color: ${black};
        font-weight: 700;
        font-size: 15px;
    }
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const ReadMoreWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const ReadMore = styled(Link)`
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid ${lightGray};
    margin-left: 1.5rem;
    color: ${darkGreen};
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    text-decoration: none;
    padding: 0.75rem 2.5rem;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background: ${lightGraishButton};
    }
`;
