import React, { useState, useEffect, FC, ReactNode } from 'react';
import { Slider, Card } from './InterestedInSliderStyle';
import InterestedIn from '../../Page/ContentTypes/InterestedIn/InterestedIn';
import { FieldBannerInterface } from '../../../interfaces/page';
import { LeftArrowWrapper, RightArrowWrapper, LeftArrow, RightArrow } from '../../../styles/Common';
import { DESKTOP_SCREEN_SIZE, TABLET_SCREEN_SIZE } from '../../../styles/MediaQuery';

interface InterestedInSliderInterface {
    bannerData: Array<Array<FieldBannerInterface>>;
}

const InterestedInSlider: FC<InterestedInSliderInterface> = ({ bannerData }) => {
    const numberOfCards = bannerData.length;
    const [browserWidth, setBrowserWidth] = useState<number>(
        typeof window !== 'undefined' ? window.innerWidth : DESKTOP_SCREEN_SIZE
    );
    const [numberOfCardsToDisplay, setNumberOfCardsToDisplay] = useState<number>(
        browserWidth > TABLET_SCREEN_SIZE + 1 ? 2 : 1
    );
    const [lastActiveDesktopPhoto, setLastActiveDesktopPhoto] = useState<number>(1);
    const [lastActiveMobilePhoto, setLastActiveMobilePhoto] = useState<number>(0);

    useEffect(() => {
        let handleResize = () => {};
        if (typeof window !== 'undefined') {
            handleResize = () => setBrowserWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    useEffect(() => {
        setNumberOfCardsToDisplay(browserWidth > TABLET_SCREEN_SIZE + 1 ? 2 : 1);
    }, [browserWidth]);

    const displayInterestedIn = (): ReactNode =>
        bannerData
            ?.filter((banner: Array<FieldBannerInterface>) => banner.length > 0)
            .map((banner: Array<FieldBannerInterface>, index: number) => (
                <Card
                    key={banner[0]?.url}
                    position={index}
                    numberOfPhotos={numberOfCards}
                    lastActiveDesktopPhoto={lastActiveDesktopPhoto}
                    lastActiveMobilePhoto={lastActiveMobilePhoto}
                >
                    <InterestedIn banner={banner[0]} />
                </Card>
            ));

    const nextImage = (): void => {
        lastActiveDesktopPhoto < numberOfCards - 1
            ? setLastActiveDesktopPhoto((lastActiveDesktopPhoto) => ++lastActiveDesktopPhoto)
            : setLastActiveDesktopPhoto(numberOfCardsToDisplay - 1);
        lastActiveMobilePhoto < numberOfCards - 1
            ? setLastActiveMobilePhoto((lastActiveMobilePhoto) => ++lastActiveMobilePhoto)
            : setLastActiveMobilePhoto(numberOfCardsToDisplay - 1);
    };

    const previousImage = (): void => {
        lastActiveDesktopPhoto === numberOfCardsToDisplay - 1
            ? setLastActiveDesktopPhoto(numberOfCards - 1)
            : setLastActiveDesktopPhoto((lastActiveDesktopPhoto) => --lastActiveDesktopPhoto);
        lastActiveMobilePhoto === numberOfCardsToDisplay - 1
            ? setLastActiveMobilePhoto(numberOfCards - 1)
            : setLastActiveMobilePhoto((lastActiveMobilePhoto) => --lastActiveMobilePhoto);
    };

    return (
        <Slider>
            {displayInterestedIn()}
            {numberOfCardsToDisplay < numberOfCards && (
                <>
                    <LeftArrowWrapper onClick={previousImage}>
                        <LeftArrow />
                    </LeftArrowWrapper>
                    <RightArrowWrapper onClick={nextImage}>
                        <RightArrow />
                    </RightArrowWrapper>
                </>
            )}
        </Slider>
    );
};

export default InterestedInSlider;
