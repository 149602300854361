import { ContainerCommonWithoutMargin } from '../../styles/Common';
import { greyBorder, darkGreen } from '../../styles/Variables';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import styled from 'styled-components';

export const TopArrowsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: -53px;

    * {
        box-sizing: border-box;
    }
`;

interface ArrowWrapperInterface {
    readonly isBorderBottom?: boolean;
}

export const ArrowNextWrapper = styled.div<ArrowWrapperInterface>`
    border-bottom: ${(props) => (props.isBorderBottom ? `1px solid ${greyBorder}` : 'unset')};
    border-left: 1px solid ${greyBorder};
    min-width: 3rem;
    height: 3.3125rem;
    padding-top: 0.875rem;
    padding-left: 0.875rem;
    display: flex;
`;

export const ArrowPreviousWrapper = styled.div<ArrowWrapperInterface>`
    border-bottom: ${(props) => (props.isBorderBottom ? `1px solid ${greyBorder}` : 'unset')};
    border-right: 1px solid ${greyBorder};
    min-width: 3rem;
    height: 3.3125rem;
    padding-top: 0.875rem;
    padding-right: 0.875rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
`;

export const ArrowNextArticle = styled(FaChevronRight)`
    fill: #7bac2c;
    font-size: 1.4rem;
`;

export const ArrowPreviousArticle = styled(FaChevronLeft)`
    fill: #7bac2c;
    font-size: 1.4rem;
`;

export const ArrowBottomContainer = styled(ContainerCommonWithoutMargin)`
    margin-top: 80px;
    border-top: 1px solid ${greyBorder};
`;

export const ArrowText = styled.span`
    color: ${darkGreen};
    font-family: Arial, Helvetica;
    font-size: 0.875rem;
    padding: 0.15rem;
`;
