import React, { ReactNode, useEffect, useState } from 'react';
import {
    ContactsWrapper,
    Container,
    WrapperHeadline,
    PersonWrapper,
    PersonTitle,
    PersonContentBox,
    PersonImage,
    PersonContactDetails,
    WrapperText,
    PersonName,
    PersonPhone,
    PersonEmail,
} from './LeafletsStyle';
import { useFetchLeaflets } from '../../hooks/useFetchLeaflets';
import { LeafletInterface } from '../../interfaces/page';
import { urlForImage } from '../../utils/urlForImage';
import placeHolder from '../../static/images/placeholder.jpg';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Leaflets = () => {
    const { language } = useI18next();
    const [leaflets, setLeaflets] = useState<Array<LeafletInterface>>();

    const getLeaflets = async (): Promise<void> => {
        const data = await useFetchLeaflets();
        setLeaflets(data);
    };

    useEffect(() => {
        getLeaflets();
    }, []);

    const displayContactPerson = (): ReactNode =>
        leaflets
            ?.filter(
                (item: LeafletInterface) =>
                    item?.field_location !== null && item?.field_latitude !== null && item?.field_longitude !== null
            )
            ?.map((item: LeafletInterface, index: number) => (
                <PersonWrapper key={`${item?.field_location}${index}`} id={`${item?.field_location}${index}`}>
                    <PersonTitle to={`/${language}${item?.url}`}>{item.field_location}</PersonTitle>
                    <PersonContentBox>
                        <PersonImage
                            src={
                                item?.field_image?.length > 0 && item.field_image[0]?.field_media_image
                                    ? urlForImage(item.field_image[0]?.field_media_image)
                                    : placeHolder
                            }
                            alt={item?.title ?? 'person image'}
                        />
                        <PersonContactDetails>
                            <PersonName>{item?.title}</PersonName>
                            <PersonPhone href={`tel:${item?.field_phone}`}>{item?.field_phone}</PersonPhone>
                            <PersonEmail href={`mailto:${item?.field_email}`}>{item?.field_email}</PersonEmail>
                        </PersonContactDetails>
                    </PersonContentBox>
                </PersonWrapper>
            ));
    if (typeof window !== 'undefined') {
        return (
            <Container>
                {leaflets && leaflets?.length > 0 && (
                    <>
                        <WrapperHeadline>WIR SIND GERNE FÜR SIE DA</WrapperHeadline>
                        <WrapperText>
                            Von Aachen bis Zwenkau stehen Ihnen unsere Druckluftexperten gern zur Verfügung.
                        </WrapperText>
                        <ContactsWrapper>{displayContactPerson()}</ContactsWrapper>
                    </>
                )}
            </Container>
        );
    }
    return null;
};

export default Leaflets;
