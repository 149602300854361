import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { FC, ReactNode } from 'react';
import {
    NextIcon,
    SlideImg,
    SlideTextContent,
    SlideTextContentWrapper,
    SlideTitle,
    SlideWrapper,
    TextWrapper,
    TitleLinkWrapper,
    TitleWrapper,
} from './SlideStyle';
import { linkHelper } from '../../../../../utils/linkHelper';

type StringNull = string | null;

interface SlideProps {
    imgSrc: StringNull;
    imgAlt: StringNull;
    text: StringNull;
    title: StringNull;
    projectText: StringNull;
    projectTitle: StringNull;
    position: StringNull;
    url: StringNull;
}

const Slide: FC<SlideProps> = ({ imgSrc, imgAlt, text, title, projectText, projectTitle, position, url }) => {
    const { language } = useI18next();

    const getSlideText = (title: string, text: string): ReactNode => (
        <SlideTextContent>
            {url?.length > 0 ? (
                <TitleLinkWrapper to={linkHelper(url, language)}>
                    <NextIcon />
                    <SlideTitle isLink={true}>{title}</SlideTitle>
                </TitleLinkWrapper>
            ) : (
                <TitleWrapper>
                    <NextIcon />
                    <SlideTitle>{title}</SlideTitle>
                </TitleWrapper>
            )}
            <TextWrapper dangerouslySetInnerHTML={{ __html: text }}></TextWrapper>
        </SlideTextContent>
    );

    return (
        <SlideWrapper>
            <SlideTextContentWrapper position={position}>
                {title && text && getSlideText(title, text)}
                {projectTitle && projectText && getSlideText(projectTitle, projectText)}
            </SlideTextContentWrapper>
            <SlideImg position={position} src={imgSrc} alt={imgAlt} />
        </SlideWrapper>
    );
};

export default Slide;
