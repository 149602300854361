import React, { FC, ReactNode } from 'react';
import { SliderParagraphInterface } from '../../../../interfaces/page';
import Slide from './Slide/Slide';
import { Container, SliderSubtitle, SliderTitle, SliderWrapper } from './SliderWithTextStyle';

interface SliderWithTextProps {
    sliderData: SliderParagraphInterface;
}

const SliderWithText: FC<SliderWithTextProps> = ({
    sliderData: { field_title, field_subtitle, field_hide_title, field_slides },
}) => {
    const displaySlides = (): ReactNode =>
        field_slides
            .filter((slide: any) => slide?.length > 0)
            .map((slide: any, index: number) => (
                <Slide
                    key={slide[0]?.alt + index}
                    imgSrc={slide[0]?.url}
                    imgAlt={slide[0]?.alt}
                    text={slide[0]?.field_text?.length > 0 && slide[0].field_text[0].value}
                    title={slide[0]?.field_title?.length > 0 && slide[0].field_title[0].value}
                    projectText={slide[0]?.field_project_text?.length && slide[0].field_project_text[0].value}
                    projectTitle={slide[0]?.field_project_title?.length && slide[0].field_project_title[0].value}
                    position={slide[0]?.field_above_below?.length > 0 && slide[0].field_above_below[0].value}
                    url={slide[0]?.field_box_url?.length > 0 && slide[0].field_box_url[0].uri}
                />
            ));

    return (
        <Container>
            {field_subtitle?.length > 0 && field_subtitle[0]?.value !== null && (
                <SliderSubtitle>{field_subtitle[0].value}</SliderSubtitle>
            )}
            {field_title?.length > 0 &&
                field_title[0].value !== null &&
                field_hide_title?.length > 0 &&
                field_hide_title[0].value === '0' && <SliderTitle>{field_title[0].value}</SliderTitle>}
            <SliderWrapper>{field_slides?.length > 0 && displaySlides()}</SliderWrapper>
        </Container>
    );
};

export default SliderWithText;
