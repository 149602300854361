import React, { FC } from 'react';
import { FieldBannerInterface } from '../../../../interfaces/page';
import { Container, Text, TextWrapper, Image, ReadMore, ReadMoreWrapper } from './InterestedInStyle';
import { urlForImage } from '../../../../utils/urlForImage';
import { linkHelper } from '../../../../utils/linkHelper';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface InterestedInInterface {
    banner: FieldBannerInterface;
}

const InterestedIn: FC<InterestedInInterface> = ({ banner }) => {
    const {
        i18n: { language },
    } = useTranslation();

    return (
        <Container>
            {banner.field_text && (
                <TextWrapper>
                    <Text dangerouslySetInnerHTML={{ __html: banner.field_text }} />
                </TextWrapper>
            )}
            {banner.url && <Image src={urlForImage(banner.url)} alt={banner.alt as string} />}
            {banner.field_banner_link?.length > 0 && banner.field_banner_link[0]?.uri && (
                <ReadMoreWrapper>
                    <ReadMore to={linkHelper(banner.field_banner_link[0].uri, language)}>
                        {banner.field_banner_link[0].title}
                    </ReadMore>
                </ReadMoreWrapper>
            )}
        </Container>
    );
};

export default InterestedIn;
