import React, { ReactNode, useState } from 'react';
import { BsQuestion } from 'react-icons/bs';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { FiFileText } from 'react-icons/fi';
import { BiCheck } from 'react-icons/bi';
import {
    BodyItem,
    Button,
    CloseIcon,
    Container,
    DescIcon,
    DescriptionContainer,
    DescriptionContent,
    DownIcon,
    IconsBox,
    ProductsListContainer,
    ProductsListItem,
    ProductsListTitle,
    ProjectInfoBox,
    ProjectInfoBoxHeadline,
    Subtitle,
} from './CredentialsStyle';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

const Credentials = ({ data }) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const [isVisible, setIsVisible] = useState(false);
    const [buttonIsVisible, setButtonIsVisible] = useState(true);
    const challengeParagraph = 0;
    const solutionParagraph = 1;
    const resultParagraph = 2;

    const displayProductsList = (): ReactNode => (
        <ProductsListContainer>
            <ProductsListTitle>{t('productsInUse')}</ProductsListTitle>
            {data.field_product_list.map((item: any, index: number) => (
                <ProductsListItem
                    key={item?.title ?? index}
                    to={item?.url?.length > 0 ? `/${language}${item.url}` : '#'}
                >
                    {item?.title}
                </ProductsListItem>
            ))}
        </ProductsListContainer>
    );

    return (
        <>
            <Container>
                <ProjectInfoBox>
                    <ProjectInfoBoxHeadline>{t('projectInfos')}</ProjectInfoBoxHeadline>
                    <Subtitle>{data?.field_subtitle}</Subtitle>
                    <BodyItem>
                        <span>
                            <BsQuestion />
                        </span>
                        {data?.body?.length > challengeParagraph && (
                            <div dangerouslySetInnerHTML={{ __html: data?.body[challengeParagraph] }}></div>
                        )}
                    </BodyItem>
                    <BodyItem>
                        <span>
                            <HiOutlineLightBulb />
                        </span>
                        {data?.body?.length > solutionParagraph && (
                            <div dangerouslySetInnerHTML={{ __html: data?.body[solutionParagraph] }}></div>
                        )}
                    </BodyItem>
                    <BodyItem>
                        <span>
                            <BiCheck />
                        </span>
                        {data?.body?.length > resultParagraph && (
                            <div dangerouslySetInnerHTML={{ __html: data?.body[resultParagraph] }}></div>
                        )}
                    </BodyItem>
                    {data?.field_project_info && buttonIsVisible && (
                        <Button
                            onClick={() => {
                                setIsVisible((isVisible) => !isVisible);
                                setButtonIsVisible((buttonIsVisible) => !buttonIsVisible);
                            }}
                        >
                            {t('readProjectReport')} <DownIcon />
                        </Button>
                    )}
                </ProjectInfoBox>
            </Container>
            {isVisible && (
                <DescriptionContainer>
                    <IconsBox>
                        <DescIcon>
                            <FiFileText />
                        </DescIcon>
                        <CloseIcon
                            onClick={() => {
                                setIsVisible((isVisible) => !isVisible);
                                setButtonIsVisible((buttonIsVisible) => !buttonIsVisible);
                            }}
                        >
                            <AiOutlineClose />
                        </CloseIcon>
                    </IconsBox>
                    {data?.field_project_info?.length > 0 && (
                        <DescriptionContent>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data?.field_project_info[0]?.field_projectinfo_description,
                                }}
                            ></div>
                        </DescriptionContent>
                    )}
                </DescriptionContainer>
            )}
            {data?.field_product_list?.length > 0 && displayProductsList()}
        </>
    );
};

export default Credentials;
