import React, { FC } from 'react';
import { ShowcaseGroupItemInterface } from '../../../../../interfaces/page';
import { Container, ItemImage, List, ListItem } from './ShowcaseItemStyle';
import { urlForImage } from '../../../../../utils/urlForImage';

export interface ShowcaseItemProps {
    data: ShowcaseGroupItemInterface;
    activeItem: string;
}

const ShowcaseItem: FC<ShowcaseItemProps> = ({
    data: { field_title, field_image, field_showcase_item_list },
    activeItem,
}) => (
    <Container isActive={field_title === activeItem}>
        {field_image?.length > 0 && (
            <ItemImage
                isActive={field_title === activeItem}
                src={urlForImage(field_image[0].url)}
                alt={`${field_image[0].alt} image`}
            />
        )}
        {field_showcase_item_list.length > 0 && (
            <List isActive={field_title === activeItem}>
                {field_showcase_item_list.map((item: any, index: number) => (
                    <ListItem isActive={field_title === activeItem} key={index}>
                        {item.value}
                    </ListItem>
                ))}
            </List>
        )}
    </Container>
);

export default ShowcaseItem;
