import styled from 'styled-components';
import { ContainerColumnStart, SendButtonGreyGradient } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';

export const Container = styled(ContainerColumnStart)`
    margin: 4rem auto;

    @media ${deviceMax.tablet} {
        width: calc(100% - 30px);
    }
`;

export const Text = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px 0 0 0;
    padding: 0;
    word-break: break-word;
`;

export const InputText = styled.input`
    width: 180px;
    margin: 10px 0;
    border: 1px solid #cdcecf;
    padding: 9px 40px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
`;

export const RedirectButton = styled(SendButtonGreyGradient)`
    min-width: 150px;
    padding: 12px 30px;
`;
