import React, { ReactNode } from 'react';
import SliderWithDots from '../../Sliders/SliderWithDots/SliderWithDots';
import AdvancedText from './AdvancedText/AdvancedText';
import DescriptionBlockWithImage from './DescriptionBlockWithImage/DescriptionBlockWithImage';
import ExpandableTextList from './ExpandableTextList/ExpandableTextList';
import QuoteParagraph from './QuoteParagraph/QuoteParagraph';
import {
    ExpandableTextItemInterface,
    FieldImageInterface,
    FieldWithValueInterface,
    ShowcaseGroupItemInterface,
    SimpleTextInterface,
    SliderParagraphInterface,
} from '../../../interfaces/page';
import {
    exapndableTextExample,
    fieldHighlightsExampleResponse,
    overviewBannerCardExampleResponse,
    quoteParagraphFieldImageExample,
    quoteParagraphFieldWithValueExample,
    showcaseGroupExample,
    simpleTextExampleResponse,
    sliderParagraphExampleResponse,
} from '../../../templateObjects/pageObjects';
import ShowcaseGroup from './ShowcaseGroup/ShowcaseGroup';
import { urlForImage } from '../../../utils/urlForImage';
import { MultiCardSliderCardInterface } from '../../../interfaces/multiCardSliderCard';
import MultiCardSlider from '../../Sliders/MultiCardSlider/MultiCardSlider';
import { multiCardSliderCard } from '../../../templateObjects/multiCardSliderCard';
import { CommonH3 } from '../../../styles/Common';
import { MultiCardSliderWrapper } from '../../../templates/page/pageStyle';
import SimpleText from './SimpleText/SimpleText';
import ServiceGroup from './ServiceGroup/ServiceGroup';
import OverviewBannerCard from './OverviewBannerCard/OverviewBannerCard';
import Highlighted from './Highlighted/Highlighted';
import SliderWithText from './SliderWithText/SliderWithText';
import ParagraphWrapper from './ParagraphWrapper/ParagraphWrapper';
import WebForm from '../../WebForm/WebForm';
import {
    ADVANCED_TEXT_PARAGRAPH,
    BANNER_PARAGRAPH,
    DESCRIPTION_BLOCK_WITH_IMAGE_PARAGRAPH,
    EXPANDABLE_TEXT_PARAGRAPH,
    HIGHLIGHTED_PARAGRAPH,
    PARAGRAPH_WRAPPER,
    QUOTE_PARAGRAPH,
    SERVICES_PARAGRAPH,
    SHOWCASE_PARAGRAPH,
    SIMPLE_TEXT_PARAGRAPH,
    SLIDER_PARAGRAPH,
    WEBFORM,
} from '../../../constants/paragraphTypeNames';

const displayMultiSliderSection = (data: any, key: any, cards: Array<MultiCardSliderCardInterface>): ReactNode => (
    <MultiCardSliderWrapper key={key}>
        {data.field_title?.length > 0 && data.field_title[0].value && <CommonH3>{data.field_title[0].value}</CommonH3>}
        <MultiCardSlider cards={cards} />
    </MultiCardSliderWrapper>
);

export const checkParagraphType = (type: string, data: any, key: any): ReactNode => {
    switch (type) {
        case ADVANCED_TEXT_PARAGRAPH:
            return <AdvancedText item={data} key={key} />;
        case DESCRIPTION_BLOCK_WITH_IMAGE_PARAGRAPH:
            return <DescriptionBlockWithImage item={data} key={key} />;
        case EXPANDABLE_TEXT_PARAGRAPH:
            const expandableTextData: Array<ExpandableTextItemInterface> =
                data?.field_text_list ?? exapndableTextExample;
            return <ExpandableTextList items={expandableTextData} key={key} />;
        case SLIDER_PARAGRAPH:
            const sliderData: SliderParagraphInterface = data ?? sliderParagraphExampleResponse;
            return sliderData?.field_add_text?.length > 0 && sliderData?.field_add_text[0]?.value === '1' ? (
                <SliderWithText sliderData={sliderData} key={key} />
            ) : (
                <SliderWithDots sliderData={sliderData} key={key} />
            );
        case QUOTE_PARAGRAPH:
            const quoteDataImage: Array<FieldImageInterface> =
                data?.field_image?.length > 0 ? data.field_image : quoteParagraphFieldImageExample;
            const quoteDataImageAligment: Array<FieldWithValueInterface> =
                data?.field_image_aligment?.length > 0
                    ? data.field_image_aligment
                    : quoteParagraphFieldWithValueExample;
            const quoteDataShowTitle: Array<FieldWithValueInterface> =
                data?.field_show_title?.length > 0 ? data.field_show_title : quoteParagraphFieldWithValueExample;
            const quoteDataText: Array<FieldWithValueInterface> =
                data?.field_text?.length > 0 ? data.field_text : quoteParagraphFieldWithValueExample;
            const quoteDataTitle: Array<FieldWithValueInterface> =
                data?.field_title?.length > 0 ? data.field_title : quoteParagraphFieldWithValueExample;
            return (
                <QuoteParagraph
                    key={key}
                    field_image={quoteDataImage}
                    field_image_aligment={quoteDataImageAligment}
                    field_show_title={quoteDataShowTitle}
                    field_text={quoteDataText}
                    field_title={quoteDataTitle}
                />
            );
        case SHOWCASE_PARAGRAPH:
            const showcaseData: Array<ShowcaseGroupItemInterface> =
                data?.field_showcase_items ?? showcaseGroupExample.field_showcase_items;
            return <ShowcaseGroup key={key} showcaseGroupData={showcaseData} />;
        case BANNER_PARAGRAPH:
            if (data?.field_banner_type?.length > 0 && data.field_banner_type[0].value === 'slider') {
                const cards: Array<MultiCardSliderCardInterface> = [];
                data.field_banner?.length > 0 &&
                    data.field_banner.forEach((card: any) => {
                        if (Array.isArray(card)) {
                            card?.length > 0 &&
                                cards.push({
                                    title: card[0]?.title ?? '',
                                    image: card[0]?.url ? urlForImage(card[0].url) : '',
                                    subTitle: card[0]?.field_subtitle ?? '',
                                    description: card[0]?.field_text ?? '',
                                    linkText:
                                        card[0]?.field_banner_link?.length > 0
                                            ? card[0].field_banner_link[0].title
                                            : 'Read more',
                                    link:
                                        card[0]?.field_banner_link?.length > 0 ? card[0].field_banner_link[0].uri : '#',
                                });
                        } else {
                            cards.push({
                                title: card?.title ?? '',
                                image: card?.url ? urlForImage(card.url) : '',
                                subTitle: card?.field_subtitle ?? '',
                                description: card?.field_text ?? '',
                                linkText:
                                    card?.field_banner_link?.length > 0 ? card.field_banner_link[0].title : 'Read more',
                                link: card?.field_banner_link?.length > 0 ? card.field_banner_link[0].uri : '#',
                            });
                        }
                    });
                if (cards.length > 0) {
                    return displayMultiSliderSection(data, key, cards);
                } else {
                    return displayMultiSliderSection(data, key, [multiCardSliderCard]);
                }
            } else if (data?.field_banner_type?.length > 0 && data.field_banner_type[0].value === 'overview') {
                return (
                    <OverviewBannerCard key={key} overviewBannerCardData={data ?? overviewBannerCardExampleResponse} />
                );
            }
            break;
        case SIMPLE_TEXT_PARAGRAPH:
            const simpleTextData: SimpleTextInterface = data ?? simpleTextExampleResponse;
            return <SimpleText key={key} item={simpleTextData} />;
        case SERVICES_PARAGRAPH:
            return <ServiceGroup key={key} data={data?.field_service_groups} />;
        case HIGHLIGHTED_PARAGRAPH:
            const highlightedData =
                data?.field_highlights?.length > 0 ? data.field_highlights : fieldHighlightsExampleResponse;
            return <Highlighted key={key} highlightedData={highlightedData} />;
        case PARAGRAPH_WRAPPER:
            return <ParagraphWrapper key={key} {...data} />;
        case WEBFORM:
            return (
                <WebForm
                    language={data?.language}
                    webformid={data?.field_webform[0]?.target_id}
                    confirmationTitle={data?.title}
                    key={key}
                    deleteMarginBottom
                    isParagraphType
                />
            );
        default:
            return null;
    }
};
