export const UPS_CODE_DE = 'de_DE';
export const UPS_CODE_DE_AT = 'de_AT';
export const UPS_CODE_EN = 'en_US';
export const UPS_CODE_EN_AU = 'en_AU';
export const UPS_CODE_EN_IN = 'en_IN';
export const UPS_CODE_EN_SG = 'en_SG';
export const UPS_CODE_EN_UK = 'en_GB';
export const UPS_CODE_EN_US = 'en_US';
export const UPS_CODE_ES = 'es_ES';
export const UPS_CODE_ES_ES = 'es_ES';
export const UPS_CODE_ES_US = 'es_US';
export const UPS_CODE_FR = 'fr_FR';
export const UPS_CODE_FR_BE = 'fr_BE';
export const UPS_CODE_FR_FR = 'fr_FR';
export const UPS_CODE_IT = 'it_IT';
export const UPS_CODE_NL_BE = 'nl_BE';
export const UPS_CODE_NL_NL = 'nl_NL';
export const UPS_CODE_ZH_HANS = 'zh_CN';
