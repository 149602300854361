export const ADVANCED_TEXT_PARAGRAPH = 'advenced_text';
export const DESCRIPTION_BLOCK_WITH_IMAGE_PARAGRAPH = 'description_block_with_image';
export const EXPANDABLE_TEXT_PARAGRAPH = 'expandable_text';
export const SLIDER_PARAGRAPH = 'slider';
export const QUOTE_PARAGRAPH = 'quote';
export const SHOWCASE_PARAGRAPH = 'showcase';
export const BANNER_PARAGRAPH = 'banner';
export const SIMPLE_TEXT_PARAGRAPH = 'simple_text';
export const SERVICES_PARAGRAPH = 'services';
export const HIGHLIGHTED_PARAGRAPH = 'highlighted';
export const NEWS_PARAGRAPH = 'news';
export const PARAGRAPH_WRAPPER = 'paragraph_wrapper';
export const WEBFORM = 'webform';
