import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
} from '../constants/languageCode';

export const getCaseStudiesUrl = (language: string): string => {
    switch (language) {
        case DE:
            return 'referenzen';
        case DE_AT:
            return 'referenzen';
        case EN_AU:
            return 'case-studies';
        case EN_IN:
            return 'case-studies';
        case EN_SG:
            return 'case-studies';
        case EN_UK:
            return 'case-studies';
        case EN_US:
            return 'case-studies';
        case ES_ES:
            return 'referencias';
        case FR_FR:
            return 'references';
        case IT:
            return 'referenze';
        case NL_NL:
            return 'referenties';
        case NL_BE:
            return 'referenties';
        case ZH_HANS:
            return 'case-studies';
        case EN:
            return 'case-studies';
        case FR:
            return 'references';
        case FR_BE:
            return 'references';
        case ES_US:
            return 'referencias';
        case ES:
            return 'referencias';
        default:
            return 'referenzen';
    }
};
