import axios from 'axios';
import { LeafletInterface } from '../interfaces/page';
import { leafletsExampleResponse } from '../templateObjects/pageObjects';

export const useFetchLeaflets = async (): Promise<Array<LeafletInterface>> => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query fetchLeaflets {
                        leaflets {
                            items {
                                field_email
                                field_latitude
                                field_location
                                field_longitude
                                field_phone
                                title
                                field_image {
                                    field_media_image
                                    alt
                                }
                                url
                            }
                        }
                    }
            `,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return data?.data?.data?.leaflets?.items ?? leafletsExampleResponse;
};
