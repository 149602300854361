import React, { FC } from 'react';
import { Link } from 'gatsby';
import {
    TopArrowsWrapper,
    ArrowNextArticle,
    ArrowPreviousArticle,
    ArrowNextWrapper,
    ArrowPreviousWrapper,
} from '../ArticleNavigationStyle';
import { ContainerCommonWithoutMargin } from '../../../styles/Common';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { linkHelper } from '../../../utils/linkHelper';

interface ArticleNavigationArrowsInterface {
    previousPage: string;
    nextPage: string;
}

const ArticleNavigationArrows: FC<ArticleNavigationArrowsInterface> = ({ previousPage, nextPage }) => {
    const { language } = useI18next();

    return (
        <ContainerCommonWithoutMargin>
            <TopArrowsWrapper>
                <Link to={linkHelper(previousPage, language)}>
                    <ArrowPreviousWrapper isBorderBottom={true}>
                        <ArrowPreviousArticle />
                    </ArrowPreviousWrapper>
                </Link>
                <Link to={linkHelper(nextPage, language)}>
                    <ArrowNextWrapper isBorderBottom={true}>
                        <ArrowNextArticle />
                    </ArrowNextWrapper>
                </Link>
            </TopArrowsWrapper>
        </ContainerCommonWithoutMargin>
    );
};

export default ArticleNavigationArrows;
