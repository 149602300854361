import { StaticImage } from 'gatsby-plugin-image';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { getUpsLangcode } from '../../../utils/getUpsLangcode';
import { Container, InputText, RedirectButton, Text } from './TrackingPageStyle';

const TrackingPage = () => {
    const { language } = useI18next();
    const { t } = useTranslation();
    const [trackingNumber, setTrackingNumber] = useState<string>('');
    const trackingAddress = 'https://www.ups.com/track';

    const handleTrackingShippment = (): void => {
        if (typeof window !== 'undefined' && trackingNumber.length > 0) {
            const langCode = getUpsLangcode(language);
            window.open(`${trackingAddress}?loc=${langCode}&tracknum=${trackingNumber}`, '_blank');
            setTrackingNumber('');
        }
    };

    return (
        <Container>
            <StaticImage src="../../../static/images/ups_logo.png" alt="ups logo" />
            <Text>{t('trackingText')}</Text>
            <InputText
                value={trackingNumber}
                placeholder={t('trackingPlaceholder')}
                onChange={(e) => setTrackingNumber(e.target.value)}
            />
            <RedirectButton hoverEffect={true} onClick={handleTrackingShippment}>
                {t('track')}
            </RedirectButton>
        </Container>
    );
};

export default TrackingPage;
