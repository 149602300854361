import React from 'react';
import {
    Circle,
    ServiceLink,
    Container,
    ServiceList,
    Service,
    ImageWrapper,
    ServiceLinkText,
    ServiceListMobile,
    ServiceMobile,
} from './ServiceGroupStyle';
import { urlForImage } from '../../../../utils/urlForImage';

const ServiceGroup = ({ data }) => (
    <Container>
        <Circle numberOfItems={data?.length} />
        <ServiceList>
            {data
                ?.filter((service: any) => service)
                ?.map((service: any, key: number) => (
                    <Service key={key} numberOfItems={data?.length}>
                        <ServiceLink to={service.field_link}>
                            {service.field_image?.length > 0 && service.field_image[0]?.url && (
                                <ImageWrapper>
                                    <img src={urlForImage(service.field_image[0].url)} alt={service.field_headline} />
                                </ImageWrapper>
                            )}
                        </ServiceLink>
                        <ServiceLink to={service.field_link}>
                            <ServiceLinkText isBorderVisible={key !== 5}>{service.field_headline}</ServiceLinkText>
                        </ServiceLink>
                    </Service>
                ))}
        </ServiceList>
        <ServiceListMobile>
            {data
                ?.filter((service: any) => service)
                ?.map((service: any, key: number) => (
                    <ServiceMobile key={key}>
                        <ServiceLink to={service.field_link}>
                            {service.field_image?.length > 0 && service.field_image[0]?.url && (
                                <ImageWrapper>
                                    <img src={urlForImage(service.field_image[0].url)} alt={service.field_headline} />
                                </ImageWrapper>
                            )}
                        </ServiceLink>
                        <ServiceLink to={service.field_link}>
                            <ServiceLinkText>{service.field_headline}</ServiceLinkText>
                        </ServiceLink>
                    </ServiceMobile>
                ))}
        </ServiceListMobile>
    </Container>
);

export default ServiceGroup;
