import {
    ExpandableTextItemInterface,
    FieldEventInterface,
    FieldHighlightsInterface,
    FieldImageInterface,
    FieldSlidesItemInterface,
    FieldWithValueInterface,
    LeafletInterface,
    OverviewBannerCardInterface,
    ShowcaseGroupInterface,
    SimpleTextInterface,
    SliderParagraphInterface,
} from '../interfaces/page';
export const quoteParagraphFieldImageExample: Array<FieldImageInterface> = [
    {
        title: null,
        url: null,
    },
];

export const quoteParagraphFieldWithValueExample: Array<FieldWithValueInterface> = [
    {
        value: null,
    },
];

export const showcaseGroupExample: ShowcaseGroupInterface = {
    field_showcase_items: [
        {
            field_image: [
                {
                    alt: null,
                    title: null,
                    url: null,
                },
            ],
            field_showcase_item_list: [
                {
                    value: null,
                },
            ],
            field_title: null,
        },
    ],
};

export const exapndableTextExample: ExpandableTextItemInterface[] = [
    {
        field_headline: '',
        field_hide_title: '',
        field_text: '',
        field_title: '',
    },
];

export const simpleTextExampleResponse: SimpleTextInterface = {
    field_grey_for_5_points: [
        {
            value: null,
        },
    ],
    field_short_text: [
        {
            value: null,
        },
    ],
    field_show_title: [
        {
            value: null,
        },
    ],
    field_subtitle: [
        {
            value: null,
        },
    ],
    field_text: [
        {
            value: null,
        },
    ],
    field_title: [
        {
            value: null,
        },
    ],
};

export const fieldSlidesItemExampleResponse: FieldSlidesItemInterface[] = [
    {
        url: null,
        alt: null,
        field_title: [
            {
                value: null,
            },
        ],
        field_project_text: [
            {
                value: null,
            },
        ],
        field_project_title: [
            {
                value: null,
            },
        ],
        field_text: [
            {
                value: null,
            },
        ],
    },
];

export const fieldEventExampleResponse: FieldEventInterface[] = [
    {
        url: null,
        title: null,
        field_image: [
            {
                alt: null,
                field_media_image: null,
            },
        ],
        summary: null,
    },
];

export const sliderParagraphExampleResponse: SliderParagraphInterface = {
    field_hide_title: [
        {
            value: null,
        },
    ],
    field_title: [
        {
            value: null,
        },
    ],
    field_slides: [
        {
            url: null,
            alt: null,
            field_title: [
                {
                    value: null,
                },
            ],
            field_subtitle: [
                {
                    value: null,
                },
            ],
            field_project_text: [
                {
                    value: null,
                },
            ],
            field_project_title: [
                {
                    value: null,
                },
            ],
            field_text: [
                {
                    value: null,
                },
            ],
        },
    ],
    field_add_text: [
        {
            value: null,
        },
    ],
};

export const overviewBannerCardExampleResponse: OverviewBannerCardInterface = {
    field_banner_type: null,
    field_banner: [
        {
            alt: null,
            field_banner_link: [
                {
                    title: null,
                    uri: null,
                },
            ],
            field_banner_type: null,
            url: null,
            field_text: null,
            title: null,
            field_subtitle: null,
        },
    ],
    field_add_product_search: [
        {
            value: null,
        },
    ],
};

export const fieldHighlightsExampleResponse: FieldHighlightsInterface[] = [
    {
        description: null,
        link: null,
        link_label: null,
        title: null,
    },
];

export const leafletsExampleResponse: LeafletInterface[] = [
    {
        field_email: null,
        field_latitude: null,
        field_location: null,
        field_longitude: null,
        field_phone: null,
        title: null,
        field_image: [
            {
                field_media_image: null,
                alt: null,
            },
        ],
        url: null,
    },
];
