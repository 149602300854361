import React, { FC } from 'react';
import { Link } from 'gatsby';
import {
    TopArrowsWrapper,
    ArrowNextArticle,
    ArrowPreviousArticle,
    ArrowNextWrapper,
    ArrowPreviousWrapper,
    ArrowBottomContainer,
    ArrowText,
} from '../ArticleNavigationStyle';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { linkHelper } from '../../../utils/linkHelper';

interface ArticleNavigationArrowsTextInterface {
    previousPage: string;
    nextPage: string;
}

const ArticleNavigationArrowsText: FC<ArticleNavigationArrowsTextInterface> = ({ previousPage, nextPage }) => {
    const { t } = useTranslation();
    const { language } = useI18next();

    return (
        <ArrowBottomContainer>
            <TopArrowsWrapper>
                <Link to={linkHelper(previousPage, language)}>
                    <ArrowPreviousWrapper>
                        <ArrowPreviousArticle />
                        <ArrowText>{t('previousArticle')}</ArrowText>
                    </ArrowPreviousWrapper>
                </Link>
                <Link to={linkHelper(nextPage, language)}>
                    <ArrowNextWrapper>
                        <ArrowText>{t('nextArticle')}</ArrowText>
                        <ArrowNextArticle />
                    </ArrowNextWrapper>
                </Link>
            </TopArrowsWrapper>
        </ArrowBottomContainer>
    );
};

export default ArticleNavigationArrowsText;
