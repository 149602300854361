import React, { FC, useState } from 'react';
import { ExpandableTextItemInterface } from '../../../../../interfaces/page';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { ExpandableBox, ExpandableButton, ExpandableContent } from './ExpandableTextListItemStyle';

interface ExpandableTextListItemProps {
    item: ExpandableTextItemInterface;
}

export const ExpandableTextListItem: FC<ExpandableTextListItemProps> = ({ item }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <ExpandableBox
            onClick={() => {
                setIsActive((isActive) => !isActive);
            }}
        >
            <ExpandableButton isActive={isActive}>
                {isActive ? <IoIosArrowDown /> : <IoIosArrowForward />}
                <span>{item?.field_headline}</span>
            </ExpandableButton>
            <ExpandableContent
                isActive={isActive}
                dangerouslySetInnerHTML={{ __html: item?.field_text }}
            ></ExpandableContent>
        </ExpandableBox>
    );
};

export default ExpandableTextListItem;
