import React, { FC, ReactNode } from 'react';
import { FieldBannerInterface, OverviewBannerCardInterface } from '../../../../interfaces/page';
import {
    Container,
    OverviewCard,
    OverviewImage,
    OverviewTitle,
    OverviewTitleBox,
    RedirectionButton,
    SearchIcon,
} from './OverviewBannerCardStyle';
import placeHolder from '../../../../static/images/placeholder.jpg';
import { urlForImage } from '../../../../utils/urlForImage';
import { Link } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { linkHelper } from '../../../../utils/linkHelper';
import { generateStaticUrl, StaticPages } from '../../../../utils/generateStaticUrl';

interface OverviewBannerCardProps {
    overviewBannerCardData: OverviewBannerCardInterface;
}

const OverviewBannerCard: FC<OverviewBannerCardProps> = ({
    overviewBannerCardData: { field_add_product_search, field_banner },
}) => {
    const { t } = useTranslation();
    const { language } = useI18next();

    const displayCards = (): ReactNode =>
        field_banner?.map((item: any) => {
            if (Array.isArray(item)) {
                return item?.map((item: FieldBannerInterface, index: number) => {
                    let urlPathName = linkHelper(item.field_banner_link[0]?.uri, language);
                    return (
                        <OverviewCard key={item?.field_subtitle ?? index}>
                            <OverviewTitleBox>
                                <OverviewTitle to={urlPathName ?? '#'}>{item?.field_subtitle}</OverviewTitle>
                            </OverviewTitleBox>
                            <Link to={urlPathName ?? '#'}>
                                <OverviewImage
                                    src={item?.url?.length > 0 ? urlForImage(item.url) : placeHolder}
                                    alt={item?.alt ?? `card${index} img`}
                                />
                            </Link>
                        </OverviewCard>
                    );
                });
            }
        });

    return (
        <Container>
            {field_add_product_search?.length > 0 && field_add_product_search[0].value === '1' && (
                <OverviewCard isProductSearch={true}>
                    <SearchIcon />
                    <RedirectionButton to={generateStaticUrl(language, StaticPages.Product)}>
                        {t('goToProductSearch')}
                    </RedirectionButton>
                </OverviewCard>
            )}
            {field_banner?.length > 0 && displayCards()}
        </Container>
    );
};

export default OverviewBannerCard;
