import styled from 'styled-components';
import { SectionHeadline } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';
import {
    blue,
    darkGreen,
    greyBorder,
    greyText,
    lightGraishButton,
    lightGray,
    lightGreyButton,
    paragraphWidthRem,
} from '../../../styles/Variables';

export const Container = styled.div`
    width: ${paragraphWidthRem};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    @media ${deviceMax.tablet} {
        right: 0;
        width: 90%;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;
    border-top: 1px solid ${greyBorder};
    border-bottom: 1px solid ${greyBorder};
    padding: 1rem 0;
`;

interface NewsPageProps {
    readonly isNews?: boolean;
    readonly isShowDate?: boolean;
}

export const ContentBox = styled.div<NewsPageProps>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0 2rem 0;
    width: 120%;
    ${(props) =>
        (props.isShowDate || props.isNews) &&
        `
            position: relative;
            left: -160px;

            @media (max-width: 920px) {
                left: 0;
            }
        `}

    @media (max-width: 920px) {
        width: unset;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

export const DateText = styled.p<NewsPageProps>`
    min-width: 140px;
    font-weight: 400;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
    color: ${greyText};
    margin: 0;
    margin-right: 20px;
    padding: 0;
    text-align: right;

    @media (max-width: 920px) {
        margin-right: 0;
        margin-bottom: 0.5rem;
        text-align: left;
    }
`;

export const NewsContent = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 155px 1fr;
    grid-gap: 20px;

    @media ${deviceMax.tablet} {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

export const Image = styled.img`
    width: 155px;

    @media ${deviceMax.tablet} {
        margin-bottom: 1rem;
        width: 100%;
        height: auto;
        max-height: 360px;
    }
`;

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media ${deviceMax.tablet} {
        margin-left: 0;
    }
`;

export const EventLocation = styled(SectionHeadline)`
    text-transform: capitalize;
`;

export const Title = styled.h3`
    font-size: 1.5rem;
    color: ${blue};
    margin: 0;
    padding: 0;
    text-decoration: none;
    word-break: break-word;
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;

    * {
        font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
        font-size: 1.5rem;
        color: ${blue};
        margin: 0;
        padding: 0;
        text-decoration: none;
    }
`;

export const Description = styled.div`
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.25rem;
    * {
        font-size: 0.875rem;
        font-weight: 200;
        line-height: 1.25rem;
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const Button = styled.button`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    color: ${darkGreen};
    border: 1px solid ${lightGray};
    min-width: 9.4em;
    padding: 0.75em 1.875em;
    border-radius: 5px;
    cursor: pointer;
    margin: 1rem 0 3rem 0;
`;
