import React, { useEffect, useState } from 'react';
import {
    BoxWithImage,
    Container,
    Subtitle,
    Title,
    Image,
    TextBox,
    Icon,
    LinkBox,
} from './DescriptionBlockWithImageStyle';
import { BsArrowRightShort } from 'react-icons/bs';
import { urlForImage } from '../../../../utils/urlForImage';

const DescriptionBlockWithImage = ({ item }) => {
    const [image, setImage] = useState([]);

    const getImages = (): Array<any> => {
        let images = [];
        if (Array.isArray(item?.field_image) && item?.field_image?.length > 0) {
            item?.field_image?.map((item: any) => {
                if (item) {
                    images.push(item);
                }
            });
        }
        return images;
    };

    useEffect(() => {
        setImage(getImages());
    }, []);

    return (
        <Container>
            {item?.field_title?.length > 0 && item.field_title[0]?.value && <Title>{item.field_title[0].value}</Title>}
            {item?.field_subtitle?.length > 0 && item?.field_subtitle[0]?.value && (
                <Subtitle>{item?.field_subtitle[0]?.value}</Subtitle>
            )}
            <BoxWithImage>
                {image.length > 0 && (
                    <Image
                        float={item.hasOwnProperty('field_image_aligment') && item.field_image_aligment[0]?.value}
                        src={urlForImage(image[0]?.url)}
                    />
                )}
                <TextBox>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: item.hasOwnProperty('field_description') && item?.field_description[0]?.value,
                        }}
                    ></div>
                    <LinkBox>
                        {Array.isArray(item?.field_link) && item?.field_link?.length > 0 && (
                            <a href={item?.field_link[0]?.uri}>
                                <Icon>
                                    <BsArrowRightShort />
                                </Icon>
                                {item?.field_link[0]?.title}
                            </a>
                        )}
                    </LinkBox>
                </TextBox>
            </BoxWithImage>
        </Container>
    );
};

export default DescriptionBlockWithImage;
