import styled from 'styled-components';
import { ContainerColumnStartWithoutWidth } from '../../../../../styles/Common';
import { deviceMax } from '../../../../../styles/MediaQuery';
import { blue } from '../../../../../styles/Variables';
import dotList from '../../../../../static/images/dotlist.png';

interface IsActiveProps {
    readonly isActive: boolean;
}

export const Container = styled(ContainerColumnStartWithoutWidth)<IsActiveProps>`
    ${(props) => (props.isActive ? `width: 100%; height: 100%` : `width: 0; height: 0`)};
    transition: height 0.3s;

    @media ${deviceMax.tabletS} {
        display: flex;
        flex-direction: column;
        align-items: center;
        ${(props) => (props.isActive ? `width: 85%` : `width: 0;`)};
    }
`;

export const ItemImage = styled.img<IsActiveProps>`
    ${(props) => (props.isActive ? `width: calc(100% - 20px);` : `width: 0;`)};
    object-fit: cover;
    transition: width 0.3s;

    @media ${deviceMax.tabletS} {
        ${(props) => (props.isActive ? `height: 118px; width: 220px; margin-top: 1rem;` : `height: 0; width: 0;`)};
    }
`;

export const List = styled.ul<IsActiveProps>`
    ${(props) => (props.isActive ? `display: block; width: 100%` : `display: none; width: 0`)};
    list-style: none;
    margin: 0;
    margin-top: 20px;
    padding: 0;

    @media ${deviceMax.tabletS} {
        ${(props) => (props.isActive ? `width: 220px;` : `width: 0;`)};
    }
`;

export const ListItem = styled.li<IsActiveProps>`
    background: url(${dotList}) no-repeat scroll 0px 8px transparent;
    background-size: 9px;
    width: ${(props) => (props.isActive ? '92%' : '0')};
    word-break: break-word;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0;
    padding-left: 16px;
    font-family: Arial, Helvetica;
    transition: 0.4s;
    line-height: 26px;
    margin-top: 2px;
`;

export const Bullet = styled.span`
    width: 0.5rem;
    height: 0.5rem;
    background: ${blue};
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
`;
