import styled from 'styled-components';
import { blueBackground, greenText, white } from '../../../../../styles/Variables';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'gatsby';

interface SlideProps {
    position?: string;
    isLink?: boolean;
}

export const SlideWrapper = styled.div`
    display: grid;
    grid-template-rows: 320px 158px;
`;

export const SlideTextContent = styled.div<SlideProps>`
    background-color: ${blueBackground};
    padding: 10px;
    min-width: calc(50% - 20px);
    width: 100%;
`;

export const TitleLinkWrapper = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 4px 0 8px 0;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 4px 0 8px 0;
`;

export const NextIcon = styled(IoIosArrowForward)`
    fill: ${greenText};
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    margin-left: -5px;
`;

export const SlideTitle = styled.h3<SlideProps>`
    font-size: 15px;
    font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
    color: ${white};
    margin: 0;
    line-height: 1.25rem;
    height: 14px;

    ${(props) =>
        props.isLink &&
        `
        &:hover {
            color: ${greenText};
        }
    `}
`;

export const TextWrapper = styled.div`
    font-size: 0.875rem;
    color: ${white};
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.25rem;
`;

export const SlideImg = styled.img<SlideProps>`
    width: 100%;
    height: 100%;
    grid-area: 2 / 1 / 3 / 2;
    grid-area: ${(props) => (props.position === '1' ? '2 / 1 / 3 / 2' : '1 / 1 / 2 / 2')};
`;

interface SlideTextContentWrapperProps {
    position?: string;
}

export const SlideTextContentWrapper = styled.div<SlideTextContentWrapperProps>`
    display: flex;
    flex-direction: row;
    grid-area: ${(props) => (props.position === '1' ? '1 / 1 / 2 / 2' : '2 / 1 / 3 / 2')};
`;
