import styled from 'styled-components';
import { blue, greenText, white, blueBackground, paragraphMargin } from '../../../../styles/Variables';
import { SmallContainerColumn } from '../../../../styles/Common';
import { Link } from 'gatsby';
import { IoIosArrowForward } from 'react-icons/io';

interface LinkProps {
    isLink?: boolean;
}

interface Grid {
    columns?: number;
}

const containerWidth = 40;

export const ParagraphWrapperContainer = styled(SmallContainerColumn)`
    width: ${containerWidth}em;
    margin: 0 auto;
    margin-top: ${paragraphMargin};
`;

export const Title = styled.h2`
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${blue};
    margin: 0.5rem 0;
    letter-spacing: 0.025em;
`;

export const Subtitle = styled.h3`
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
    font-size: 15px;
    text-transform: uppercase;
    margin: 12px 0 7px 0;
    letter-spacing: 0.025em;
`;

export const ParagraphBox = styled.div<Grid>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns ?? 1}, 1fr);
    gap: 10px;

    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const TitleLinkWrapper = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const TitleLink = styled.h3<LinkProps>`
    font-size: 15px;
    font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
    color: ${white};
    line-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 10px 0;

    ${(props) =>
        props.isLink &&
        `
        &:hover {
            color: ${greenText};
        }
    `}
`;

export const TextWrapper = styled.div`
    font-size: 0.875rem;
    * {
        color: ${white};
        margin: 0 auto 5px auto;
        width: 92%;
        font-size: 0.875rem;
    }
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.25rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

export const TextContent = styled.div`
    height: 152px;
    background-color: ${blueBackground};
`;

export const Image = styled.img`
    width: 100%;
    height: 154px;
`;

export const Paragraph = styled.div<Grid>`
    height: 100%;
    width: ${(props) => containerWidth / props.columns}rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        width: 100%;
    }
`;

export const NextIcon = styled(IoIosArrowForward)`
    fill: ${greenText};
    font-size: 1.5rem;
    margin-left: 5px;
    padding: 0;
`;
