import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    ContentBox,
    DownIcon,
    Image,
    Subtitle,
    Text,
    TextWrapper,
    Title,
    UpIcon,
} from './AdvancedTextStyle';
import { urlForImage } from '../../../../utils/urlForImage';

const AdvancedText = ({ item }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const [image, setImage] = useState([]);

    const checkIsImages = (): Array<any> => {
        let images = [];
        if (Array.isArray(item?.field_expandable_image) && item?.field_expandable_image?.length > 0) {
            item?.field_expandable_image?.map((item: any) => {
                if (item) {
                    images.push(item);
                }
            });
        }
        return images;
    };

    useEffect(() => {
        setImage(checkIsImages());
    }, []);

    return (
        <Container>
            {item?.field_subtitle?.length > 0 && item?.field_subtitle[0]?.value && (
                <Subtitle>{item?.field_subtitle[0]?.value}</Subtitle>
            )}
            {item?.field_hide_title?.length > 0 && item?.field_hide_title[0]?.value !== '1' && (
                <Title>{item?.field_title[0]?.value}</Title>
            )}
            <ContentBox>
                {image.length > 0 && (
                    <Image
                        src={urlForImage(image[0]?.url)}
                        float={item?.field_image_aligment[0]?.value?.toLowerCase() ?? 'left'}
                    />
                )}
                {item?.field_short_text && (
                    <TextWrapper>
                        {Array.isArray(item?.field_short_text) &&
                            item?.field_short_text[0]?.hasOwnProperty('value') && (
                                <Text dangerouslySetInnerHTML={{ __html: item?.field_short_text[0]?.value }}></Text>
                            )}
                        {isActive && <Text dangerouslySetInnerHTML={{ __html: item?.field_text[0]?.value }}></Text>}
                        {Array.isArray(item?.field_link) && item?.field_link?.length > 0 && (
                            <Link to={item?.field_link[0]?.uri}>{item?.field_link[0]?.title}</Link>
                        )}
                    </TextWrapper>
                )}
            </ContentBox>
            {Array.isArray(item?.field_text) && item?.field_text[0]?.hasOwnProperty('value') && (
                <Button onClick={() => setIsActive((isActive) => !isActive)}>
                    {isActive ? (
                        <>
                            {t('readLess')} <UpIcon />{' '}
                        </>
                    ) : (
                        <>
                            {t('readMore')} <DownIcon />
                        </>
                    )}
                </Button>
            )}
        </Container>
    );
};

export default AdvancedText;
