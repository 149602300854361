import styled from 'styled-components';
import { SmallContainerColumn } from '../../../../styles/Common';
import { deviceMax } from '../../../../styles/MediaQuery';
import {
    blue,
    darkGreen,
    greyLight,
    linkOnHover,
    paragraphMargin,
    paragraphWidthRem,
    white,
} from '../../../../styles/Variables';

export const Container = styled(SmallContainerColumn)`
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    margin-top: ${paragraphMargin};
    width: ${paragraphWidthRem};

    a {
        text-decoration: none;
        color: ${darkGreen};

        :hover {
            color: ${linkOnHover};
            text-decoration: none;
        }
    }

    @media ${deviceMax.tablet} {
        width: 90%;
    }
`;

export const Title = styled.h3`
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
    font-size: 0.9375rem;
`;

export const Subtitle = styled.h2`
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${blue};
    margin: 0;
    padding: 0;
    margin-bottom: 1.5rem;
`;

export const BoxWithImage = styled.div`
    display: flex;
    width: 100%;
    background: ${greyLight};

    @media ${deviceMax.tablet} {
        flex-direction: column;
    }
`;

interface ImageProps {
    readonly float: string;
}

export const Image = styled.img<ImageProps>`
    float: ${(props) => props.float};
    height: 160px;
    align-self: center;
    object-fit: cover;

    @media ${deviceMax.tablet} {
        height: auto;
        width: 100%;
    }
`;

export const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.625rem;

    * {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
`;

export const LinkBox = styled.div`
    a {
        display: flex;
        align-items: center;
    }
`;

export const Icon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    background: ${darkGreen};
    color: ${white};
    cursor: pointer;
    margin-right: 1rem;

    * {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${white};
        font-size: 1.5rem;
    }
`;
