import { MultiCardSliderCardInterface } from '../interfaces/multiCardSliderCard';

export const multiCardSliderCard: MultiCardSliderCardInterface = {
    title: '',
    image: '',
    subTitle: '',
    description: '',
    linkText: '',
    link: '',
};
