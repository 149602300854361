import React, { FC, useEffect, useState, useRef, ReactNode } from 'react';
import { FieldSlidesItemInterface, SliderParagraphInterface } from '../../../interfaces/page';
import { SectionTitle } from '../../../styles/Common';
import { fieldSlidesItemExampleResponse } from '../../../templateObjects/pageObjects';
import {
    Bullet,
    Bullets,
    SlideContainer,
    SlideImage,
    SliderContainer,
    SlidesContainer,
    TextContainer,
    TextContainerContent,
    TextContainerTitle,
    SectionWrapper,
    SlideImageWrapper,
} from './SliderWithDotsStyle';
import { urlForImage } from '../../../utils/urlForImage';

interface SliderWithDotsProps {
    sliderData: SliderParagraphInterface;
}

const SliderWithDots: FC<SliderWithDotsProps> = ({ sliderData: { field_title, field_hide_title, field_slides } }) => {
    const [activePhotoIndex, setActivePhotoIndex] = useState(0);
    const [resetTimer, setResetTimer] = useState(false);
    const [slides, setSlides] = useState<FieldSlidesItemInterface[]>(
        field_slides?.length > 0 ? field_slides : fieldSlidesItemExampleResponse
    );
    const sliderRef = useRef(null);
    const [sliderHeight, setSliderHeight] = useState(420);
    const sliderTextRef = useRef<HTMLDivElement>(null);
    const numberOfPhotos = field_slides?.length;
    const INTERVAL_TIME = 4500;
    const imgRef = useRef(null);
    let interval;

    useEffect(() => {
        updateSliderHeight();

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', updateSliderHeight);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', updateSliderHeight);
            }
        };
    }, []);

    useEffect(() => {
        interval = setInterval(() => {
            setActivePhotoIndex((activePhotoIndex) => ++activePhotoIndex);
        }, INTERVAL_TIME);
        return () => clearInterval(interval);
    }, [resetTimer]);

    useEffect(() => {
        activePhotoIndex >= numberOfPhotos && setActivePhotoIndex(0);
    }, [activePhotoIndex]);

    const updateSliderHeight = (): void => {
        const sliderPanelHeight = sliderRef?.current?.clientHeight;

        if (sliderPanelHeight) {
            setSliderHeight(sliderPanelHeight);
        }
    };

    const changePhoto = (index: number): void => {
        setResetTimer((resetTimer) => !resetTimer);
        setActivePhotoIndex(index);
    };

    const displayBullets = (): ReactNode =>
        slides.map((item: any, index: number) => (
            <Bullet
                key={index}
                onClick={() => {
                    setActivePhotoIndex(index);
                    changePhoto(index);
                }}
                isActive={index === activePhotoIndex}
            ></Bullet>
        ));

    const onImageLoad = (): void => {
        const imageHeight = imgRef?.current?.clientHeight;
        const textHeight = sliderTextRef?.current?.clientHeight;

        if (imageHeight) {
            if (textHeight) {
                setSliderHeight(imageHeight + textHeight);
            } else {
                setSliderHeight(imageHeight);
            }
        }
    };

    const displaySlider = (withBullets: boolean): ReactNode =>
        slides.map((item: FieldSlidesItemInterface, index: number) =>
            item.map((item: FieldSlidesItemInterface) => (
                <SlideContainer ref={sliderRef} isActive={index === activePhotoIndex} key={`${item?.alt}${index}`}>
                    <SlideImageWrapper>
                        <SlideImage
                            ref={imgRef}
                            src={urlForImage(item?.url)}
                            alt={item?.alt ?? 'slide image'}
                            onLoad={onImageLoad}
                        />
                        {withBullets && <Bullets>{displayBullets()}</Bullets>}
                    </SlideImageWrapper>
                    {item?.field_title?.length > 0 && item?.field_text?.length > 0 && (
                        <TextContainer ref={sliderTextRef}>
                            <SectionWrapper>
                                <TextContainerTitle>{item.field_title[0].value}</TextContainerTitle>
                                <TextContainerContent
                                    dangerouslySetInnerHTML={{ __html: item.field_text[0].value }}
                                ></TextContainerContent>
                            </SectionWrapper>
                            {item.field_project_title?.length > 0 && item.field_project_text?.length > 0 && (
                                <SectionWrapper>
                                    <TextContainerTitle>{item.field_project_title[0]?.value}</TextContainerTitle>
                                    <TextContainerContent
                                        dangerouslySetInnerHTML={{ __html: item.field_project_text[0]?.value }}
                                    ></TextContainerContent>
                                </SectionWrapper>
                            )}
                        </TextContainer>
                    )}
                </SlideContainer>
            ))
        );

    return (
        <SliderContainer>
            {field_title.length > 0 && field_hide_title?.length > 0 && field_hide_title[0].value !== '1' && (
                <SectionTitle>{field_title[0].value}</SectionTitle>
            )}
            <SlidesContainer sliderHeight={sliderHeight}>
                {field_slides && displaySlider(field_slides?.length > 1)}
            </SlidesContainer>
        </SliderContainer>
    );
};

export default SliderWithDots;
