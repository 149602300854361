import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import { FieldHighlightsInterface } from '../../../../interfaces/page';
import {
    HighlightedContainer,
    HighlightedItem,
    HighlightedItemButton,
    HighlightedItemText,
    HighlightedItemTitle,
    HIGHLIGHTED_MEDIA_BREAKPOINT,
} from './HighlightedStyle';
import { LeftArrow, LeftArrowWrapper, RightArrow, RightArrowWrapper } from '../../../../styles/Common';
import useBrowserWidth from '../../../../hooks/useBrowserWidth';
interface HighlightedProps {
    highlightedData: FieldHighlightsInterface[];
}

const Highlighted: FC<HighlightedProps> = ({ highlightedData }) => {
    const numberOfItems = highlightedData.length;
    const browserWidth = useBrowserWidth();
    const [lastActiveCardDesktop, setLastActiveCardDesktop] = useState(1);
    const [lastActiveCardMobile, setLastActiveCardMobile] = useState(0);
    const [highlightItemHeight, setHighlightItemHeight] = useState(0);
    const highlightedRef = useRef([]);

    useEffect(() => {
        calculateItemHeight(true);
        const calculateSlider = () => calculateItemHeight(false);

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', calculateSlider);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', calculateSlider);
            }
        };
    }, []);

    const calculateItemHeight = (isFirstRender: boolean): void => {
        const calculate = (): void => {
            let highestElement = 0;
            highlightedRef?.current?.forEach((highlight) => {
                let tempHeight = 0;
                highlight?.childNodes.forEach((child: any) => {
                    tempHeight += child.offsetHeight;
                });
                highestElement = tempHeight > highestElement ? tempHeight : highestElement;
            });

            if (highlightItemHeight < highestElement) {
                setHighlightItemHeight(highestElement);
            }
        };

        isFirstRender ? calculate() : setTimeout(calculate, 1000);
    };

    const nextItem = (): void => {
        lastActiveCardDesktop < numberOfItems - 1
            ? setLastActiveCardDesktop((lastActiveCardDesktop) => ++lastActiveCardDesktop)
            : setLastActiveCardDesktop(1);
        lastActiveCardMobile < numberOfItems - 1
            ? setLastActiveCardMobile((lastActiveCardMobile) => ++lastActiveCardMobile)
            : setLastActiveCardMobile(0);
    };

    const previousItem = (): void => {
        lastActiveCardDesktop === 1
            ? setLastActiveCardDesktop(numberOfItems - 1)
            : setLastActiveCardDesktop((lastActiveCardDesktop) => --lastActiveCardDesktop);
        lastActiveCardMobile === 0
            ? setLastActiveCardMobile(numberOfItems - 1)
            : setLastActiveCardMobile((lastActiveCardMobile) => --lastActiveCardMobile);
    };

    const displayHighlightedItems = (): ReactNode =>
        highlightedData.map((item: FieldHighlightsInterface, index: number) => (
            <HighlightedItem
                key={item.title + index}
                position={index}
                lastActiveDesktop={lastActiveCardDesktop}
                lastActiveMobile={lastActiveCardMobile}
                ref={(el) => (highlightedRef.current[index] = el)}
            >
                <HighlightedItemTitle>{item.title}</HighlightedItemTitle>
                <HighlightedItemText>{item.description}</HighlightedItemText>
                {item.link?.length > 0 && item.link_label?.length > 0 && (
                    <HighlightedItemButton to={item.link}>{item.link_label}</HighlightedItemButton>
                )}
            </HighlightedItem>
        ));

    const displayArrowButtons = (): ReactNode => (
        <>
            <LeftArrowWrapper isHighlighted={true}>
                <LeftArrow onClick={previousItem} />
            </LeftArrowWrapper>
            <RightArrowWrapper isHighlighted={true}>
                <RightArrow onClick={nextItem} />
            </RightArrowWrapper>
        </>
    );

    const checkIfDisplayArrowButtons = (): ReactNode =>
        numberOfItems > 2 || (browserWidth <= HIGHLIGHTED_MEDIA_BREAKPOINT && numberOfItems > 1)
            ? displayArrowButtons()
            : null;

    return (
        <HighlightedContainer maxHeight={highlightItemHeight}>
            {displayHighlightedItems()}
            {checkIfDisplayArrowButtons()}
        </HighlightedContainer>
    );
};

export default Highlighted;
