import React, { FC, ReactNode } from 'react';
import { Container, Title } from './ExpandableTextListStyle';
import { ExpandableTextItemInterface } from '../../../../interfaces/page';
import ExpandableTextListItem from './ExpandableTextListItem/ExpandableTextListItem';

interface ExpandableTextListProps {
    items: ExpandableTextItemInterface[];
}

const ExpandableTextList: FC<ExpandableTextListProps> = ({ items }) => {
    const displayExpandableContent = (): ReactNode =>
        items.map((item: ExpandableTextItemInterface, index: number) => (
            <ExpandableTextListItem key={index + ' ' + item.field_title} item={item} />
        ));

    return (
        <Container>
            {items[0].field_title.length > 0 && <Title>{items[0].field_title}</Title>}
            {displayExpandableContent()}
        </Container>
    );
};

export default ExpandableTextList;
