import React, { FC, ReactNode } from 'react';
import { SimpleTextInterface } from '../../../../interfaces/page';
import { BackgroundContainer, Container, Subtitle, Text, TextWrapper, Title } from '../AdvancedText/AdvancedTextStyle';

interface SimpleTextProps {
    item: SimpleTextInterface;
}

const SimpleText: FC<SimpleTextProps> = ({
    item: { field_grey_for_5_points, field_short_text, field_show_title, field_subtitle, field_text, field_title },
}) => {
    const displaySimpleTextContent = (): ReactNode => (
        <Container>
            {field_subtitle?.length > 0 && <Subtitle>{field_subtitle[0]?.value}</Subtitle>}
            {field_show_title?.length > 0 && field_show_title[0]?.value !== '1' && field_title?.length > 0 && (
                <Title>{field_title[0].value}</Title>
            )}
            {field_short_text?.length > 0 && (
                <TextWrapper>
                    <Text dangerouslySetInnerHTML={{ __html: field_short_text[0].value }}></Text>
                </TextWrapper>
            )}
            {field_text?.length > 0 && (
                <TextWrapper>
                    <Text dangerouslySetInnerHTML={{ __html: field_text[0].value }}></Text>
                </TextWrapper>
            )}
        </Container>
    );

    return (
        <>
            {field_grey_for_5_points?.length > 0 ? (
                <BackgroundContainer>{displaySimpleTextContent()}</BackgroundContainer>
            ) : (
                displaySimpleTextContent()
            )}
        </>
    );
};

export default SimpleText;
