import { graphql } from 'gatsby';
import React, { useState, useEffect, ReactNode } from 'react';
import Layout from '../../components/Layout/Layout';
import Header from '../../components/HeaderDynamicPages/Header';
import {
    Body,
    Container,
    DownloadContainer,
    PersonsLable,
    Title,
    Paragraph,
    TitleSectionWrapper,
    TitleWrapper,
    ContainerL,
    InterestedInWrapper,
} from './pageStyle';
import { ContainerCommonWithoutMargin } from '../../styles/Common';
import DownloadSection from '../../components/DownloadSection/DownloadSection';
import { PotentialApplicationsTitle } from '../branch/branchStyle';
import { checkParagraphType } from '../../components/Page/ContentTypes/checkParagraphType';
import Persons from '../../components/Page/ContentTypes/Persons/Persons';
import News from '../../components/Page/News/News';
import Credentials from '../../components/Page/Credentials/Credentials';
import PrinterWrapper from '../../components/PrintWrapper/PrintWrapper';
import Webform from '../../components/WebForm/WebForm';
import { useI18next } from 'gatsby-plugin-react-i18next';
import OnlineApplication from '../../components/WebForm/OnlineApplication/OnlineApplication';
import ArticleNavigationArrows from '../../components/ArticleNavigation/ArticleNavigationArrows/ArticleNavigationArrows';
import ArticleNavigationArrowsText from '../../components/ArticleNavigation/ArticleNavigationArrowsText/ArticleNavigationArrowsText';
import { cutSlashFromTheEnd } from '../../utils/cutSlashFromTheEnd';
import { dataToBreadcrumbsFromNav } from '../../utils/dataToBreadCrumbsFromNav';
import { CREDENTIALS_PAGE, MAP_PAGE, PAGE_WITH_LIST, TRACKING_PAGE } from '../../constants/pageTypeNames';
import { sitesWithArrows } from '../../constants/sitesWithArrows';
import Leaflets from '../../components/Leaflets/Leaflets';
import InterestedInSlider from '../../components/Sliders/InterestedInSlider/InterestedInSlider';
import Alternate from '../../components/Seo/Alternate';
import HighlightedBanner from '../../components/HighlightedBanner/HighlightedBanner';
import { linkHelper } from '../../utils/linkHelper';
import { getCaseStudiesUrl } from '../../utils/getCaseStudiesUrl';
import TrackingPage from '../../components/Page/TrackingPage/TrackingPage';

const Page = ({
    data: {
        drupal: { page: page },
    },
    pageContext: { languages: languages, url: url, noIndex: noIndex },
}) => {
    const { language } = useI18next();
    const [isContainerL, setIsContainerL] = useState(false);
    const [isOverviewSite, setIsOverviewSite] = useState(false);
    const [nextPage, setNextPage] = useState('');
    const [previousPage, setPreviousPage] = useState('');
    const newsData = page?.field_embeded?.map((item) => JSON.parse(item));
    const { category, categoryItem, item, additionalItem } =
        page?.field_related_pages && nextPage?.length > 0
            ? dataToBreadcrumbsFromNav(language, `/${getCaseStudiesUrl(language)}`, `/${getCaseStudiesUrl(language)}`)
            : dataToBreadcrumbsFromNav(language, url);

    const interestedInData = page?.field_embeded
        ?.map((item) => JSON.parse(item))
        ?.filter(
            (item) =>
                item?.type === 'banner' &&
                item?.field_banner_type?.length > 0 &&
                item.field_banner_type[0]?.value === 'normal'
        );

    const displayBodyContent = (): ReactNode =>
        page?.body?.map((item: any, index: number) => (
            <Paragraph key={index} dangerouslySetInnerHTML={{ __html: item }}></Paragraph>
        ));

    const setPreviousAndNextPage = (): void => {
        if (
            page?.field_related_pages &&
            page.field_related_pages.length > 0 &&
            typeof window !== 'undefined' &&
            sitesWithArrows.some((site) => window.location.pathname.includes(site))
        ) {
            let alias = cutSlashFromTheEnd(window.location.pathname);
            page.field_related_pages.forEach((related_page, index: number) => {
                let urlPathName = cutSlashFromTheEnd(linkHelper(related_page?.field_banner_link_uri, language));
                if (urlPathName === alias) {
                    if (index === 0) {
                        setNextPage(page.field_related_pages[index + 1]?.field_banner_link_uri);
                        setPreviousPage(
                            page.field_related_pages[page.field_related_pages.length - 1]?.field_banner_link_uri
                        );
                    } else if (index === page.field_related_pages.length - 1) {
                        setNextPage(page.field_related_pages[0]?.field_banner_link_uri);
                        setPreviousPage(page.field_related_pages[index - 1]?.field_banner_link_uri);
                    } else {
                        setNextPage(page.field_related_pages[index + 1]?.field_banner_link_uri);
                        setPreviousPage(page.field_related_pages[index - 1]?.field_banner_link_uri);
                    }
                }
            });
        }
    };
    useEffect(() => {
        const dataJson = page?.field_embeded?.map((item) => JSON.parse(item));
        if (
            ((dataJson?.some((data) => data.type === 'banner') &&
                dataJson.some(
                    (data) => data?.field_banner_type?.length > 0 && data.field_banner_type[0].value === 'slider'
                )) ||
                dataJson?.some((data) => data.type === 'services')) &&
            !isContainerL
        ) {
            setIsContainerL(true);
        }
        if (
            ((dataJson?.some((data) => data.type === 'banner') &&
                dataJson.some(
                    (data) => data?.field_banner_type?.length > 0 && data.field_banner_type[0].value === 'overview'
                )) ||
                dataJson?.some((data) => data.type === 'services') ||
                dataJson?.some((data) => data.type === 'highlighted')) &&
            !isOverviewSite
        ) {
            setIsOverviewSite(true);
        }
        setPreviousAndNextPage();
    }, [page]);

    const displayParagraphs = () => {
        const dataJson = page?.field_embeded?.map((item) => JSON.parse(item));
        return dataJson
            ?.filter(
                (item) =>
                    item?.type !== 'news' &&
                    !(
                        item?.type === 'banner' &&
                        item?.field_banner_type?.length > 0 &&
                        item.field_banner_type[0]?.value === 'normal'
                    )
            )
            ?.map((item: any) => ({ ...item, title: page?.title ?? '' }))
            ?.map((item: any, index: number) => checkParagraphType(item?.type, item, index));
    };

    const displayDownloadSection = () => (
        <DownloadContainer isTitle={page?.field_attachment_title?.length > 0}>
            {page?.field_attachment_title?.length > 0 && (
                <PotentialApplicationsTitle>{page.field_attachment_title}</PotentialApplicationsTitle>
            )}
            {page?.field_attachment?.map((item, index) => (
                <DownloadSection key={index} item={item} />
            ))}
        </DownloadContainer>
    );

    const displayInterestedInSection = (): ReactNode => (
        <InterestedInWrapper>
            {interestedInData[0]?.field_title?.length > 0 && <h3>{interestedInData[0].field_title[0]?.value}</h3>}
            {interestedInData[0]?.field_banner && <InterestedInSlider bannerData={interestedInData[0].field_banner} />}
        </InterestedInWrapper>
    );

    const displayPage = (): ReactNode => (
        <>
            {page?.field_banner?.length > 0 && page.field_banner[0]?.field_media_image.length > 0 ? (
                page?.field_banner?.length > 1 ? (
                    <Header
                        isBanner={true}
                        banner_image={page?.field_banner[0]?.field_media_image}
                        banner_title={page?.field_title_not_in_header === true && page?.title}
                        color={page.field_banner[0]?.field_banner_color}
                        second_banner={page?.field_banner[1]?.field_media_image}
                        animated={page?.field_banner[1]?.field_overlay_animation}
                        youtube={page?.field_banner[0]?.field_video?.uri}
                        category={category}
                        categoryItem={categoryItem}
                        item={item}
                    />
                ) : (
                    <Header
                        isBanner={true}
                        banner_image={page?.field_banner[0]?.field_media_image}
                        banner_title={page?.field_title_not_in_header === true && page?.title}
                        color={page.field_banner[0]?.field_banner_color}
                        youtube={page?.field_banner[0]?.field_video?.uri}
                        category={category}
                        categoryItem={categoryItem}
                        item={item}
                    />
                )
            ) : (
                <Header
                    category={category}
                    categoryItem={categoryItem}
                    subCategory={additionalItem}
                    item={nextPage.length > 0 ? page?.title : item}
                />
            )}
            {page?.field_special_highlight?.length > 0 && page.field_special_highlight[0]?.field_text && (
                <HighlightedBanner data={page.field_special_highlight[0]} />
            )}
            {page?.field_related_pages && nextPage?.length > 0 && (
                <ArticleNavigationArrows nextPage={nextPage} previousPage={previousPage} />
            )}
            <Container>
                {page?.title && !isContainerL && !isOverviewSite && page?.field_choose_page_type !== MAP_PAGE && (
                    <TitleSectionWrapper isWebform={page?.field_webform}>
                        {!page?.field_special_highlight?.length && (
                            <Title titleInHeader={!!page.field_title_not_in_header}>{page.title}</Title>
                        )}
                        {page?.field_choose_page_type !== CREDENTIALS_PAGE && !isOverviewSite && (
                            <Body isPageWithList={page?.field_choose_page_type === PAGE_WITH_LIST}>
                                {displayBodyContent()}
                            </Body>
                        )}
                    </TitleSectionWrapper>
                )}
                {page?.title && (isContainerL || isOverviewSite || page?.field_choose_page_type === MAP_PAGE) && (
                    <ContainerL>
                        {!page?.field_special_highlight?.length && (
                            <TitleWrapper>
                                <Title titleInHeader={!!page?.field_title_not_in_header}>{page.title}</Title>
                            </TitleWrapper>
                        )}
                        <Body isPageWithList={page?.field_choose_page_type === PAGE_WITH_LIST}>
                            {displayBodyContent()}
                        </Body>
                    </ContainerL>
                )}
                {page?.field_choose_page_type === PAGE_WITH_LIST ? <News data={newsData} /> : displayParagraphs()}
            </Container>
            {page?.field_choose_page_type === TRACKING_PAGE && <TrackingPage />}
            {page?.field_choose_page_type === MAP_PAGE && <Leaflets />}
            {page?.field_choose_page_type === CREDENTIALS_PAGE && <Credentials data={page} />}
            {page?.field_persons?.length > 0 && (
                <>
                    <PersonsLable>{page?.field_persons_lable}</PersonsLable>
                    <Persons item={page.field_persons} />
                </>
            )}
            {page?.field_attachment && displayDownloadSection()}
            {interestedInData?.length > 0 && displayInterestedInSection()}
            {page?.field_webform && (
                <ContainerCommonWithoutMargin>
                    {page.field_webform.wid === 'online_bewerbung' ||
                    page.field_webform.wid === 'online_application' ? (
                        <OnlineApplication data={page.field_webform} title={page?.title} />
                    ) : (
                        <Webform
                            language={language}
                            webformid={page.field_webform.wid ?? ''}
                            deleteMarginBottom={false}
                            confirmationTitle={page?.title}
                        />
                    )}
                </ContainerCommonWithoutMargin>
            )}
            {page?.field_related_pages && nextPage?.length > 0 && (
                <ArticleNavigationArrowsText nextPage={nextPage} previousPage={previousPage} />
            )}
        </>
    );
    return (
        <>
            <Alternate languages={languages} />
            {page?.field_show_share_print ? (
                <Layout
                    languageVersionsUrl={languages}
                    title={page?.metatag?.field_metatag_title}
                    description={page?.metatag?.field_metatag_description}
                    noIndex={noIndex || page?.metatag?.field_metatag_no_index}
                >
                    <PrinterWrapper>{displayPage()}</PrinterWrapper>
                </Layout>
            ) : (
                <Layout
                    languageVersionsUrl={languages}
                    title={page?.metatag?.field_metatag_title}
                    description={page?.metatag?.field_metatag_description}
                    noIndex={noIndex || page?.metatag?.field_metatag_no_index}
                >
                    {displayPage()}
                </Layout>
            )}
        </>
    );
};

export default Page;

export const query = graphql`
    query page($id: Int!, $language: String!) {
        drupal {
            page(id: $id, language: $language) {
                id
                title
                body
                field_embeded
                field_attachment_title
                field_special_highlight {
                    field_text
                    field_title
                    field_subtitle
                    field_use_diamon_star_icon
                    field_use_family_icon
                }
                field_attachment {
                    description
                    field_media_document
                    details {
                        filemime
                        filename
                        weight
                    }
                }
                field_banner {
                    alt
                    field_banner_link {
                        text
                        uri
                    }
                    field_banner_color
                    field_media_image
                    field_overlay_animation
                    field_text
                    field_video {
                        text
                        uri
                    }
                    title
                }
                field_persons {
                    field_email
                    field_image {
                        field_media_image
                        alt
                    }
                    field_phone
                    field_mobile
                    field_position
                    title
                }
                field_choose_page_type
                field_list_type
                field_persons_lable
                field_project_info {
                    field_projectinfo_description
                    field_title
                }
                field_show_share_print
                field_title_not_in_header
                field_webform {
                    schema
                    link
                    submission_link
                    wid
                    settings
                }
                field_related_pages {
                    field_banner_link_uri
                    field_banner_link_title
                }
                field_product_list {
                    title
                    url
                }
                field_subtitle
                metatag {
                    field_metatag_description
                    field_metatag_title
                    field_metatag_no_index
                }
                url
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
