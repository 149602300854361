import styled from 'styled-components';
import { ContainerColumnWithoutWidth } from '../../../../styles/Common';
import { ImQuotesRight } from 'react-icons/im';
import { grayText, paragraphMargin, paragraphWidthRem, steelBlue } from '../../../../styles/Variables';
import { deviceMax } from '../../../../styles/MediaQuery';

interface IsAligment {
    isRightAligment: string;
}

export const Container = styled.div<IsAligment>`
    background: linear-gradient(135deg, #1e5799 0%, #2989d8 60%, #207cca 100%, #7db9e8 100%, #2989d8 101%);
    width: ${paragraphWidthRem};
    display: flex;
    margin-top: ${paragraphMargin};
    height: 160px;

    @media ${deviceMax.tablet} {
        width: 90%;
        flex-direction: column;
        height: auto;
    }
`;

export const TextContainer = styled(ContainerColumnWithoutWidth)<IsAligment>`
    align-items: flex-start;
    width: 55%;
    padding: 20px;
    order: ${({ isRightAligment }) => (isRightAligment !== 'left' ? 0 : 1)};

    * {
        margin: 0;
    }

    @media ${deviceMax.tablet} {
        width: calc(100% - 40px);
        order: 1;
    }
`;

export const QuoteTitle = styled.h4`
    font-weight: 700;
    font-size: 0.9375rem;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${grayText};
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
`;

export const QuoteSign = styled(ImQuotesRight)`
    font-size: 1.75rem;
    fill: ${steelBlue};
    margin: 5px 0;
`;

export const QuoteText = styled.div`
    * {
        color: ${grayText};
        font-size: 0.875rem;
        font-weight: 400;
        font-family: Arial, Helvetica;
    }
`;

export const QuoteImage = styled.img`
    height: 100%;
    width: 45%;
    object-fit: cover;

    @media ${deviceMax.tablet} {
        width: 100%;
        max-height: 320px;
    }
`;
