import styled from 'styled-components';
import { arrowGreen, black, blue, greyBorder } from '../../../../../styles/Variables';
import iconList from '../../../../../static/images/tick.png';

export const ExpandableBox = styled.div`
    border-bottom: 1px solid ${greyBorder};
    width: 100%;
    padding: 10px 0;
`;

interface IsActiveProps {
    readonly isActive?: boolean;
}

export const ExpandableButton = styled.div<IsActiveProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    * {
        font-size: 2rem;
        color: ${arrowGreen};
        margin-right: 10px;
    }

    span {
        font-family: Arial, sans-serif;
        color: ${(props) => (props.isActive ? blue : black)};
        font-size: 0.875rem;
        font-weight: 700;
        align-self: center;
        line-height: 1.25rem;
    }

    svg {
        min-width: 30px;
    }
`;

export const ExpandableContent = styled.div<IsActiveProps>`
    margin: 0;
    padding: 0;
    margin-top: ${(props) => (props.isActive ? '1.5rem' : '0px')};
    max-height: ${(props) => (props.isActive ? 'unset' : '0px')};
    opacity: ${(props) => (props.isActive ? '1' : '0')};
    transition: 0.75s;
    overflow: hidden;

    * {
        margin: 0;
        padding: 0;
    }

    p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.875rem;

        strong {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            font-family: Arial, Helvetica, sans-serif;
            background: url(${iconList}) no-repeat 0% 4px;
            background-size: 14px 14px;
            padding-left: 22px;
            line-height: 1.5rem;
            font-size: 0.875rem;

            ::marker {
                color: ${blue};
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
`;
