import React, { FC } from 'react';
import { QuoteParagraphInterface } from '../../../../interfaces/page';
import { Container, QuoteImage, QuoteSign, QuoteText, QuoteTitle, TextContainer } from './QuoteParagraphStyle';
import placeHolder from '../../../../static/images/placeholder.jpg';
import { urlForImage } from '../../../../utils/urlForImage';

const QuoteParagraph: FC<QuoteParagraphInterface> = ({
    field_image,
    field_image_aligment,
    field_show_title,
    field_text,
    field_title,
}) => (
    <Container isRightAligment={field_image_aligment[0].value}>
        <TextContainer isRightAligment={field_image_aligment[0].value}>
            {field_show_title[0].value === '1' && <QuoteTitle>{field_title[0].value}</QuoteTitle>}
            <QuoteSign />
            <QuoteText dangerouslySetInnerHTML={{ __html: field_text[0].value }}></QuoteText>
        </TextContainer>
        <QuoteImage
            src={field_image[0].url ? urlForImage(field_image[0].url) : placeHolder}
            alt={field_image[0].title ?? 'quote image'}
        />
    </Container>
);

export default QuoteParagraph;
