import React, { FC, ReactNode } from 'react';
import {
    FieldImageInterface,
    FieldWithValueInterface,
    ImageDescriptionInterface,
    LinkInterface,
    ParagraphWrapperInterface,
} from '../../../../interfaces/page';
import {
    Image,
    NextIcon,
    Paragraph,
    ParagraphBox,
    ParagraphWrapperContainer,
    Subtitle,
    TextContent,
    TextWrapper,
    Title,
    TitleLinkWrapper,
    TitleWrapper,
    TitleLink,
} from './ParagraphWrapperStyle';
import { linkHelper } from '../../../../utils/linkHelper';
import { useI18next } from 'gatsby-plugin-react-i18next';

enum DisplayColumns {
    OneColumn = 1,
    TwoColumns = 2,
    ThreeColumns = 3,
    FourColumns = 4,
}

enum Display {
    OneColumn = 'OneColumn',
    TwoColumns = 'TwoColumns',
    ThreeColumns = 'ThreeColumns',
    FourColumns = 'FourColumns',
}

enum ImagePosition {
    Top = 'top',
    Bottom = 'bottom',
}

const ParagraphWrapper: FC<ParagraphWrapperInterface> = ({
    field_title,
    field_subtitle,
    field_paragraph,
    field_display,
}) => {
    const { language } = useI18next();

    const getDisplayColumns = (display: string | null): number => {
        switch (display) {
            case Display.OneColumn:
                return DisplayColumns.OneColumn;
            case Display.TwoColumns:
                return DisplayColumns.TwoColumns;
            case Display.ThreeColumns:
                return DisplayColumns.ThreeColumns;
            case Display.FourColumns:
                return DisplayColumns.FourColumns;
            default:
                return DisplayColumns.OneColumn;
        }
    };

    const displayColumns = getDisplayColumns(field_display.length ? field_display[0]?.value : null);

    const displayTitle = (field_link: Array<LinkInterface>, field_title: Array<FieldWithValueInterface>): ReactNode => {
        const title = field_title.length ? field_title[0]?.value : '';
        if (field_link?.length) {
            const linkTitle = field_link[0]?.title && field_link[0]?.title !== '' ? field_link[0]?.title : title;
            return (
                <TitleLinkWrapper to={linkHelper(field_link[0]?.uri ?? '#', language)}>
                    <NextIcon />
                    <TitleLink isLink>{linkTitle}</TitleLink>
                </TitleLinkWrapper>
            );
        } else {
            return (
                <TitleWrapper>
                    <NextIcon />
                    <TitleLink>{title}</TitleLink>
                </TitleWrapper>
            );
        }
    };

    const displayImage = (field_image: Array<FieldImageInterface>): ReactNode => (
        <>
            {field_image?.length ? (
                <Image src={field_image[0].url} title={field_image[0].title ?? ''} alt={field_image[0].title ?? ''} />
            ) : (
                ''
            )}
        </>
    );

    const isImageOnTop = (field_image_position: Array<any>): boolean =>
        field_image_position?.length && field_image_position[0].value === ImagePosition.Top;

    const displayParagraph = (paragraph: ImageDescriptionInterface, key: number): ReactNode => (
        <Paragraph key={key} columns={displayColumns}>
            {isImageOnTop(paragraph?.field_image_position) ? displayImage(paragraph.field_image) : ''}
            <TextContent>
                {displayTitle(paragraph?.field_link, paragraph?.field_title)}
                <TextWrapper dangerouslySetInnerHTML={{ __html: paragraph?.field_description[0]?.value }} />
            </TextContent>
            {!isImageOnTop(paragraph?.field_image_position) ? displayImage(paragraph.field_image) : ''}
        </Paragraph>
    );

    const displayParagraphs = (paragraphs: Array<ImageDescriptionInterface>): ReactNode =>
        paragraphs?.map((paragraph, key) => displayParagraph(paragraph, key));

    return (
        <ParagraphWrapperContainer>
            {field_subtitle?.length && field_subtitle[0]?.value && <Subtitle>{field_subtitle[0].value}</Subtitle>}
            {field_title?.length && field_title[0]?.value && <Title>{field_title[0].value}</Title>}
            <ParagraphBox columns={displayColumns}>{displayParagraphs(field_paragraph)}</ParagraphBox>
        </ParagraphWrapperContainer>
    );
};

export default ParagraphWrapper;
