import styled from 'styled-components';
import { ContainerColumnStartWithoutWidth, SectionTitle } from '../../../../styles/Common';
import { paragraphMargin, paragraphWidthRem } from '../../../../styles/Variables';

export const Container = styled(ContainerColumnStartWithoutWidth)`
    width: ${paragraphWidthRem};
    margin-top: ${paragraphMargin};

    @media (max-width: 767px) {
        width: 100%;
        max-width: 300px;
    }
`;

export const SliderSubtitle = styled.h3`
    font-size: 15px;
    text-transform: uppercase;
    margin: 10px 0 6px 0;
`;

export const SliderTitle = styled(SectionTitle)``;

export const SliderWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 10px;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;
