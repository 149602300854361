import styled from 'styled-components';
import { deviceMax, deviceMin } from '../../styles/MediaQuery';
import { Link } from 'gatsby';
import { blue, containerWidth, greyLight } from '../../styles/Variables';
import flag from '../../static/images/boge-flag.png';

export const Container = styled.div`
    width: 100%;
    height: 143px;
    background-color: ${greyLight};
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media ${deviceMax.laptop} {
        height: unset;
    }
`;

export const HighlightedContent = styled.div`
    width: ${containerWidth};
    margin: 0 auto;
    display: flex;
    position: relative;
`;

export const HighlightedDiamondStar = styled(Link)`
    position: absolute;
    top: -41px;
    left: -35px;
    z-index: 1;

    @media ${deviceMax.laptop} {
        width: 100px;
        top: 105px;
        left: 120px;
    }

    @media (max-width: 860px) {
        top: 129px;
    }

    @media ${deviceMax.tablet} {
        display: none;
    }
`;

export const HighlightedDiamondStarImage = styled.img`
    @media ${deviceMax.laptop} {
        width: 100px;
    }
`;

export const HighlightedText = styled.div`
    * {
        margin-left: 161px;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 24px;

        @media ${deviceMax.laptop} {
            margin-left: 100px;
            padding-bottom: 30px;
            padding-top: 30px;
        }

        @media ${deviceMax.tablet} {
            margin-left: 15px;
        }
    }
`;

export const HiglightedTitleWrapper = styled.div`
    width: 100%;

    @media ${deviceMin.tablet} {
        display: none;
    }
`;

export const HighlightedTitle = styled.h1`
    color: ${blue};
    margin-left: 15px;
    font-size: 1.75rem;
    letter-spacing: 0.05px;
    margin: 2rem 0 0 15px;

    @media ${deviceMin.tablet} {
        padding-left: 0;
        display: none;
    }
`;

export const HighlightedFlag = styled.div`
    position: absolute;
    top: -25px;
    left: 5px;
    width: 100px;
    height: 135px;
    background: url(${flag});

    @media ${deviceMax.laptop} {
        left: -7px;
        top: -7px;
    }

    @media ${deviceMax.tablet} {
        left: 10px;
        top: -195px;
    }
`;

export const HighlightedFlagText = styled.div`
    color: white;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 30px;
    margin-left: 11px;
    margin-right: 10px;
    text-align: center;
`;
