import styled from 'styled-components';
import { greyBorder } from '../../../styles/Variables';
import { deviceMax, deviceMin } from '../../../styles/MediaQuery';

export const CardList = styled.ul`
    overflow: hidden;
    position: relative;
`;

interface CardType {
    readonly position?: number;
    readonly lastActiveDesktopPhoto?: number;
    readonly lastActiveMobilePhoto?: number;
    readonly numberOfPhotos?: number;
}

export const Card = styled.li<CardType>`
    width: 300px;
    min-width: 300px;
    height: 316px;
    border: 1px solid ${greyBorder};
    top: 0;
    position: absolute;
    transition: 0.3s;

    @media ${deviceMin.tablet} {
        left: ${(props) => {
            if (props.position === props.lastActiveDesktopPhoto) {
                return '50%';
            } else if (props.position === props.lastActiveDesktopPhoto - 1) {
                return '0';
            } else if (props.position < props.lastActiveDesktopPhoto - 1) {
                return '-100%';
            } else {
                return '105%';
            }
        }};
    }

    @media ${deviceMax.tablet} {
        left: ${(props) => {
            if (props.position === props.lastActiveMobilePhoto) {
                return '0';
            } else if (props.position < props.lastActiveMobilePhoto - 1) {
                return '-100%';
            } else {
                return '105%';
            }
        }};
    }
`;

export const Slider = styled.ul`
    width: 640px;
    display: flex;
    list-style: none;
    padding-left: 0;
    height: 318px;
    overflow: hidden;
    position: relative;

    @media ${deviceMax.tablet} {
        width: 320px;
    }
`;
