import React, { useState, useEffect, FC, useRef, ChangeEvent, ReactNode } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { useHandleSubmitWebform } from '../../../hooks/useHandleSubmitWebform';
import { SendButtonGreenGradient, CommonSelect } from '../../../styles/Common';
import {
    TextInput,
    FormSectionHeadline,
    InputWrapper,
    FormSectionWrapper,
    SelectWrapper,
    FileInputWrapper,
    TextAreaWrapper,
    RadioLabel,
    RadioBoxContainer,
    Note,
    SendButtonWrapper,
    SelectGroupWrapper,
    SingleSelectWrapper,
    RadioInlineWrapper,
    RadioSectionWrapper,
    DateError,
} from './OnlineApplicationStyle';
import { FormSectionTitle } from '../../ContactUk/ContactUkStyle';
import TextAreaWithGrippie from '../../Inputs/TextAreaWithGrippie/TextAreaWithGrippie';
import RadioInput from '../../Inputs/RadioInput/RadioInput';
import SelectWithSearch from '../../Inputs/SelectWithSearch/SelectWithSearch';
import FileInput from '../../Inputs/FileInput/FileInput';
import { getCountriesInCurrentLanguage } from '../../../utils/getCountriesInCurrentLanguage';
import { getMonthInCurrentLanguage } from '../../../utils/getMonthInCurrentLanguage';
import { createDate } from '../../../utils/createDate';
import { createDaysObject } from '../../../utils/createDaysObject';
import {
    DATELIST,
    DESCRIPTION,
    EMAIL,
    FIELDSET,
    MULTIPLE,
    NOTICE,
    OPTIONS,
    RADIOS,
    REQUIRED_ERROR,
    TEXTAREA,
    TEXTFIELD,
    TYPE,
    WEBFORM_DOCUMENT_FILE,
    COUNTRY_NAMES,
} from '../../../constants/commonConstants';
import { INPUT, CHECKBOX, SELECT, REQUIRED, TITLE } from '../../../constants/commonConstants';
import { DE } from '../../../constants/languageCode';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

interface OnlineApplicationInterface {
    data: any;
    title: string;
}

const OnlineApplication: FC<OnlineApplicationInterface> = ({ data, title }) => {
    const { language } = useI18next();
    const { t } = useTranslation();
    const [radios, setRadios] = useState({});
    const [requiredRadios, setRequiredRadios] = useState([]);
    const [country, setCountry] = useState(language === DE ? 'Deutschland' : '');
    const [hearAboutUs, setHearAboutUs] = useState('');
    const [files, setFiles] = useState([]);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [checkedYear, setCheckedYear] = useState('');
    const [fieldsByState, setFieldsByState] = useState({});
    const [formInputs, setFormInputs] = useState({});
    const [shouldDisplayWarning, setShouldDisplayWarning] = useState(true);
    const [dateError, setDateError] = useState(false);
    const days = createDaysObject();
    const year = new Date().getFullYear();
    const nextYear = year + 1;
    const countryList = getCountriesInCurrentLanguage(language);
    const monthsList = getMonthInCurrentLanguage(language);
    const KEY = 0;
    const VALUE = 1;
    const schema = JSON.parse(data.schema);
    const settings = JSON.parse(data.settings);
    const filteredFieldWithWebformFile = Object.entries(schema)?.filter(
        (value) => value[1][TYPE] === WEBFORM_DOCUMENT_FILE
    );
    const fieldWithFileName = filteredFieldWithWebformFile?.length > 0 ? filteredFieldWithWebformFile[0][0] : '';
    const wid = data.wid;
    const multipleFiles =
        filteredFieldWithWebformFile?.length > 0 && filteredFieldWithWebformFile[0][1].hasOwnProperty(MULTIPLE)
            ? filteredFieldWithWebformFile[0][1][MULTIPLE]
            : 1;
    const errorDateRef = useRef(null);
    const [isWebformFetching, setIsWebformFetching] = useState(false);

    useEffect(() => {
        setFieldsByState((fieldsByState) => ({ ...fieldsByState, land: country }));
    }, [country]);

    useEffect(() => {
        setFieldsByState((fieldsByState) => ({ ...fieldsByState, aufmerksam: hearAboutUs }));
    }, [hearAboutUs]);

    useEffect(() => {
        const requiredInputs = Object.values(formInputs)?.filter(
            (field: any) => field?.type === INPUT && field?.required
        );
        const checkboxFields = Object.values(formInputs)?.filter((field: any) => field?.type === CHECKBOX);
        const requiredSelects = Object.values(formInputs)?.filter(
            (field: any) => field?.type === SELECT && field?.required
        );
        if (
            Object.values(radios)?.length >= requiredRadios.length &&
            (requiredInputs?.length === 0 || requiredInputs?.every((input: any) => input?.value?.length > 0)) &&
            (checkboxFields?.length === 0 || checkboxFields?.every((checkbox: any) => checkbox?.value)) &&
            (requiredSelects?.length === 0 || requiredSelects?.every((select: any) => select?.value?.length > 0))
        ) {
            if (shouldDisplayWarning) {
                setShouldDisplayWarning(false);
            }
        } else {
            if (!shouldDisplayWarning) {
                setShouldDisplayWarning(true);
            }
        }
    }, [formInputs, radios]);

    useEffect(() => {
        if (day.length > 0 && checkedYear.length > 0 && month.length > 0) {
            let eintrittstermin = new Date(+checkedYear, +month - 1, +day);
            let oneDayAgoDate = new Date();
            oneDayAgoDate.setDate(oneDayAgoDate.getDate() - 1);
            setDateError(eintrittstermin < oneDayAgoDate);
            setFieldsByState((fieldsByState) => ({ ...fieldsByState, eintrittstermin: eintrittstermin }));
        }
    }, [day, checkedYear, month]);

    const displayMonths = (): ReactNode =>
        monthsList.map((month) => (
            <option key={month.value} value={month.value}>
                {month.name}
            </option>
        ));

    const displayFunctionOptions = (item: any): ReactNode =>
        item[VALUE].hasOwnProperty(OPTIONS) &&
        Object.entries(item[VALUE][OPTIONS]).map((option) => (
            <option value={option[KEY]} key={option[KEY]}>
                {option[VALUE]}
            </option>
        ));

    const displayCorrectField = (item: any, key: number): ReactNode => {
        switch (item[VALUE][TYPE]) {
            case FIELDSET:
                return (
                    <FormSectionWrapper key={key} deleteMarginBottom={true}>
                        {item[VALUE].hasOwnProperty(TITLE) &&
                            (item[VALUE][TITLE] === NOTICE ? (
                                shouldDisplayWarning && (
                                    <FormSectionTitle redColor={true}>{item[VALUE][TITLE]}</FormSectionTitle>
                                )
                            ) : (
                                <FormSectionTitle>{item[VALUE][TITLE]}</FormSectionTitle>
                            ))}
                        {item[VALUE].hasOwnProperty(DESCRIPTION) && (
                            <Note dangerouslySetInnerHTML={{ __html: item[VALUE][DESCRIPTION] }} />
                        )}
                        {Object?.entries(item[VALUE])?.map((item: any, index: number) => {
                            if (item.length > 1) {
                                const isRequired =
                                    item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true;

                                switch (item[VALUE][TYPE]) {
                                    case TEXTFIELD:
                                        if (!formInputs.hasOwnProperty(item[KEY])) {
                                            setFormInputs({
                                                ...formInputs,
                                                [item[KEY]]: {
                                                    value: '',
                                                    required: item[VALUE].hasOwnProperty(REQUIRED)
                                                        ? item[VALUE][REQUIRED]
                                                        : false,
                                                    type: INPUT,
                                                },
                                            });
                                        }
                                        return (
                                            <InputWrapper key={index}>
                                                <FormSectionHeadline>
                                                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                    {item[VALUE].hasOwnProperty(REQUIRED) &&
                                                        item[VALUE][REQUIRED] === true && <span>*</span>}
                                                </FormSectionHeadline>
                                                <TextInput
                                                    key={`${item[KEY]}${index}`}
                                                    name={item[KEY]}
                                                    required={
                                                        item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED]
                                                    }
                                                    onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                                                        item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                                                        item[VALUE][REQUIRED_ERROR] &&
                                                        e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                                                    }
                                                    onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                                        e.target.setCustomValidity('')
                                                    }
                                                    onChange={(e) =>
                                                        setFormInputs({
                                                            ...formInputs,
                                                            [item[KEY]]: {
                                                                ...formInputs[item[KEY]],
                                                                value: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </InputWrapper>
                                        );
                                    case EMAIL:
                                        if (!formInputs.hasOwnProperty(item[KEY])) {
                                            setFormInputs({
                                                ...formInputs,
                                                [item[KEY]]: {
                                                    value: '',
                                                    required: item[VALUE].hasOwnProperty(REQUIRED)
                                                        ? item[VALUE][REQUIRED]
                                                        : false,
                                                    type: INPUT,
                                                },
                                            });
                                        }
                                        return (
                                            <InputWrapper key={index}>
                                                <FormSectionHeadline>
                                                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                    {item[VALUE].hasOwnProperty(REQUIRED) &&
                                                        item[VALUE][REQUIRED] === true && <span>*</span>}
                                                </FormSectionHeadline>
                                                <TextInput
                                                    key={`${item[KEY]}${index}`}
                                                    name={item[KEY]}
                                                    required={
                                                        item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED]
                                                    }
                                                    type="email"
                                                    onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                                                        item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                                                        item[VALUE][REQUIRED_ERROR] &&
                                                        e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                                                    }
                                                    onInput={(e: ChangeEvent<HTMLInputElement>) =>
                                                        e.target.setCustomValidity('')
                                                    }
                                                    onChange={(e) =>
                                                        setFormInputs({
                                                            ...formInputs,
                                                            [item[KEY]]: {
                                                                ...formInputs[item[KEY]],
                                                                value: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </InputWrapper>
                                        );
                                    case SELECT:
                                        if (
                                            item[VALUE].hasOwnProperty(OPTIONS) &&
                                            item[VALUE][OPTIONS] === COUNTRY_NAMES
                                        ) {
                                            return (
                                                <SelectWrapper key={index}>
                                                    <FormSectionHeadline>
                                                        {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                        {isRequired && <span>*</span>}
                                                    </FormSectionHeadline>
                                                    <SelectWithSearch
                                                        setState={setCountry}
                                                        currentValue={country}
                                                        selectData={countryList}
                                                        defaultValue={' - '}
                                                        width={'210px'}
                                                        isRequired={isRequired}
                                                        requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                                                    />
                                                </SelectWrapper>
                                            );
                                        } else {
                                            if (!formInputs.hasOwnProperty(item[KEY])) {
                                                setFormInputs({
                                                    ...formInputs,
                                                    [item[KEY]]: {
                                                        value: '',
                                                        required: isRequired,
                                                        type: INPUT,
                                                    },
                                                });
                                            }
                                            return (
                                                <SelectWrapper key={index}>
                                                    <FormSectionHeadline>
                                                        {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                        {isRequired && <span>*</span>}
                                                    </FormSectionHeadline>
                                                    <CommonSelect
                                                        width={'350px'}
                                                        name={item[KEY]}
                                                        onChange={(e) =>
                                                            setFormInputs({
                                                                ...formInputs,
                                                                [item[KEY]]: {
                                                                    ...formInputs[item[KEY]],
                                                                    value: e.target.value,
                                                                },
                                                            })
                                                        }
                                                        onInvalid={(e: ChangeEvent<HTMLSelectElement>) =>
                                                            item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                                                            item[VALUE][REQUIRED_ERROR] &&
                                                            e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                                                        }
                                                        onInput={(e: ChangeEvent<HTMLSelectElement>) =>
                                                            e.target.setCustomValidity('')
                                                        }
                                                        required={isRequired}
                                                    >
                                                        <option value={''}>-</option>
                                                        {displayFunctionOptions(item)}
                                                    </CommonSelect>
                                                </SelectWrapper>
                                            );
                                        }
                                    case DATELIST:
                                        return (
                                            <SelectWrapper key={index}>
                                                <FormSectionHeadline>
                                                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                    {item[VALUE].hasOwnProperty(REQUIRED) &&
                                                        item[VALUE][REQUIRED] === true && <span>*</span>}
                                                </FormSectionHeadline>
                                                <SelectGroupWrapper>
                                                    <SingleSelectWrapper>
                                                        <SelectWithSearch
                                                            setState={setDay}
                                                            currentValue={day}
                                                            selectData={days}
                                                            defaultValue={t('day')}
                                                            width={'210px'}
                                                            error={dateError}
                                                            isRequired={item[VALUE][REQUIRED]}
                                                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                                                        />
                                                    </SingleSelectWrapper>
                                                    <SingleSelectWrapper>
                                                        <CommonSelect
                                                            width={'150px'}
                                                            error={dateError}
                                                            onChange={(e) => setMonth(e.target.value)}
                                                            required={item[VALUE][REQUIRED]}
                                                            onInvalid={(e: ChangeEvent<HTMLSelectElement>) =>
                                                                item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                                                                item[VALUE][REQUIRED_ERROR] &&
                                                                e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                                                            }
                                                            onInput={(e: ChangeEvent<HTMLSelectElement>) =>
                                                                e.target.setCustomValidity('')
                                                            }
                                                        >
                                                            <option value={''}>{t('month')}</option>
                                                            {displayMonths()}
                                                        </CommonSelect>
                                                    </SingleSelectWrapper>
                                                    <CommonSelect
                                                        width={'150px'}
                                                        error={dateError}
                                                        onChange={(e) => setCheckedYear(e.target.value)}
                                                        required={item[VALUE][REQUIRED]}
                                                        onInvalid={(e: ChangeEvent<HTMLSelectElement>) =>
                                                            item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                                                            item[VALUE][REQUIRED_ERROR] &&
                                                            e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                                                        }
                                                        onInput={(e: ChangeEvent<HTMLSelectElement>) =>
                                                            e.target.setCustomValidity('')
                                                        }
                                                    >
                                                        <option value={''}>{t('year')}</option>
                                                        <option value={year}>{year}</option>
                                                        <option value={nextYear}>{nextYear}</option>
                                                    </CommonSelect>
                                                </SelectGroupWrapper>
                                            </SelectWrapper>
                                        );
                                    case RADIOS:
                                        const radioName = item[KEY];
                                        const requiredErrorMessage = item[VALUE][REQUIRED_ERROR];
                                        if (
                                            item[VALUE].hasOwnProperty(REQUIRED) &&
                                            item[VALUE][REQUIRED] === true &&
                                            !requiredRadios.includes(radioName)
                                        ) {
                                            setRequiredRadios((requiredRadios) => [...requiredRadios, radioName]);
                                        }

                                        return (
                                            <RadioSectionWrapper key={key}>
                                                <FormSectionHeadline>
                                                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                                                    {isRequired && <span>*</span>}
                                                </FormSectionHeadline>
                                                {item[VALUE].hasOwnProperty(OPTIONS) &&
                                                    Object.entries(item[VALUE][OPTIONS])?.map(
                                                        (item: any, index: number) => {
                                                            return (
                                                                <RadioBoxContainer key={`${item[KEY]}${index}`}>
                                                                    <RadioInlineWrapper>
                                                                        <RadioLabel htmlFor={radioName}>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item[VALUE],
                                                                                }}
                                                                            ></div>{' '}
                                                                        </RadioLabel>
                                                                        <RadioInput
                                                                            state={radios}
                                                                            setState={setRadios}
                                                                            name={radioName}
                                                                            value={item[KEY]}
                                                                            required={isRequired}
                                                                            requiredErrorMessage={requiredErrorMessage}
                                                                        />
                                                                    </RadioInlineWrapper>
                                                                </RadioBoxContainer>
                                                            );
                                                        }
                                                    )}
                                            </RadioSectionWrapper>
                                        );
                                    default:
                                        return null;
                                }
                            }
                        })}
                    </FormSectionWrapper>
                );
            case WEBFORM_DOCUMENT_FILE:
                return (
                    <FileInputWrapper key={key}>
                        <FormSectionHeadline>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                        </FormSectionHeadline>
                        <FileInput
                            files={files}
                            setFiles={setFiles}
                            width={'340px'}
                            required={item[VALUE][REQUIRED]}
                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                        />
                    </FileInputWrapper>
                );
            case TEXTAREA:
                return (
                    <TextAreaWrapper key={key}>
                        <FormSectionHeadline>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                        </FormSectionHeadline>
                        <TextAreaWithGrippie
                            key={key}
                            name={item[KEY]}
                            required={item[VALUE][REQUIRED]}
                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                        />
                    </TextAreaWrapper>
                );
            case RADIOS:
                const radioName = item[KEY];
                const isRequired = item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true;
                const requiredErrorMessage = item[VALUE][REQUIRED_ERROR];
                if (
                    item[VALUE].hasOwnProperty(REQUIRED) &&
                    item[VALUE][REQUIRED] === true &&
                    !requiredRadios.includes(radioName)
                ) {
                    setRequiredRadios((requiredRadios) => [...requiredRadios, radioName]);
                }
                return (
                    <FormSectionWrapper key={key} deleteMarginBottom={true}>
                        <FormSectionHeadline>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {isRequired && <span>*</span>}
                        </FormSectionHeadline>
                        {item[VALUE].hasOwnProperty(OPTIONS) &&
                            Object.entries(item[VALUE][OPTIONS])?.map((item: any, index: number) => {
                                return (
                                    <RadioBoxContainer key={`${item[KEY]}${index}`}>
                                        <RadioLabel htmlFor={radioName}>
                                            <div dangerouslySetInnerHTML={{ __html: item[VALUE] }}></div>{' '}
                                        </RadioLabel>
                                        <RadioInput
                                            state={radios}
                                            setState={setRadios}
                                            name={radioName}
                                            value={item[KEY]}
                                            required={isRequired}
                                            requiredErrorMessage={requiredErrorMessage}
                                        />
                                    </RadioBoxContainer>
                                );
                            })}
                    </FormSectionWrapper>
                );
            case SELECT:
                let dataToSelect = [];
                item[VALUE].hasOwnProperty(OPTIONS) &&
                    Object.entries(item[VALUE][OPTIONS]).forEach((option) => {
                        dataToSelect.push({ name: option[KEY], value: option[VALUE] });
                    });
                return (
                    <SelectWrapper key={key}>
                        <FormSectionHeadline>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                        </FormSectionHeadline>
                        <SelectWithSearch
                            setState={setHearAboutUs}
                            currentValue={hearAboutUs}
                            selectData={dataToSelect}
                            defaultValue={`- ${t('none')} -`}
                            width={'210px'}
                            isRequired={item[VALUE][REQUIRED]}
                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                        />
                    </SelectWrapper>
                );
            default:
                return null;
        }
    };

    const scrollToError = (): void => errorDateRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });

    const submitForm = (e: any): void => {
        e.preventDefault();
        if (dateError) {
            scrollToError();
        } else {
            setIsWebformFetching(true);
            try {
                useHandleSubmitWebform(
                    e,
                    wid,
                    language,
                    settings?.confirmation_title !== '' ? settings?.confirmation_title : title,
                    requiredRadios,
                    null,
                    null,
                    fieldsByState,
                    '',
                    settings?.confirmation_url ?? null,
                    files,
                    fieldWithFileName,
                    multipleFiles,
                    settings?.confirmation_message ?? null
                );
            } catch (e) {
                setIsWebformFetching(false);
            }
        }
    };

    return (
        <form id={wid} onSubmit={(e) => submitForm(e)}>
            {dateError && (
                <DateError ref={errorDateRef}>{`${t('dateError')} ${createDate(new Date().getTime())} ${t(
                    'dateErrorEnd'
                )}`}</DateError>
            )}
            {Object.entries(schema)?.map((item: any, index: number) => displayCorrectField(item, index))}
            <input type="hidden" name="webform_id" value={wid} />
            <SendButtonWrapper>
                <SendButtonGreenGradient width="100%" disabled={isWebformFetching} type="submit">
                    {t('applyNow')}
                </SendButtonGreenGradient>
                <LoadingSpinner isLoading={isWebformFetching} />
            </SendButtonWrapper>
        </form>
    );
};

export default OnlineApplication;
