import React, { ReactNode, useEffect, useState } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import {
    Container,
    ContentBox,
    DateText,
    NewsContent,
    TextContent,
    Image,
    Title,
    Description,
    Button,
    ContentContainer,
    EventLocation,
} from './NewsStyle';
import { urlForImage } from '../../../utils/urlForImage';
import { Link } from 'gatsby';
import { checkParagraphType } from '../ContentTypes/checkParagraphType';
import { ADVANCED_TEXT_PARAGRAPH, NEWS_PARAGRAPH } from '../../../constants/paragraphTypeNames';
import { createDate } from '../../../utils/createDate';
import { createRangeDate } from '../../../utils/createRangeDate';
import { prepareSiteRedirection } from '../../../utils/prepareSiteRedirection';
import { linkHelper } from '../../../utils/linkHelper';

const News = ({ data }) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const [currentLength, setCurrentLength] = useState(10);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    (item?.type === NEWS_PARAGRAPH && item?.field_event?.length > 0) ||
                    item?.type === ADVANCED_TEXT_PARAGRAPH
            )
        );
    }, [data]);

    const displayAdvancedTextItems = (item: any, index: number): ReactNode => (
        <ContentBox
            isNews={item?.type !== NEWS_PARAGRAPH && parseInt(item.field_hide_date[0]?.value ?? '0') !== 1}
            key={index}
            isShowDate={parseInt(item.field_hide_date[0]?.value ?? '0') !== 1}
        >
            {displayDate([item], true)}
            <NewsContent>
                <Image src={item?.field_image?.length > 0 && urlForImage(item?.field_image[0]?.url)} />
                <TextContent>
                    <Title>
                        <Link to={item?.field_link?.length > 0 ? linkHelper(item?.field_link[0]?.uri, language) : '#'}>
                            {item?.field_title?.length > 0 && item?.field_title[0]?.value}
                        </Link>
                    </Title>
                    {item?.field_text?.length > 0 && (
                        <Description dangerouslySetInnerHTML={{ __html: item?.field_text[0]?.value }}></Description>
                    )}
                </TextContent>
            </NewsContent>
        </ContentBox>
    );

    const displayDate = (field_event: any, forceRender: boolean = false): ReactNode => {
        if (field_event[0]?.field_hide_date[0]?.value === '1') {
            return '';
        } else if (
            (field_event[0]?.field_event_date?.length > 0 || field_event[0]?.field_date_range?.length > 0) &&
            field_event[0]?.field_show_end_date?.length > 0 &&
            field_event[0].field_show_end_date[0]?.value === '1'
        ) {
            const startDate =
                field_event[0]?.field_event_date?.length > 0
                    ? field_event[0]?.field_event_date[0]?.value
                    : field_event[0]?.field_date_range[0]?.value;
            const endDate =
                field_event[0]?.field_event_date?.length > 0
                    ? field_event[0]?.field_event_date[0]?.end_value
                    : field_event[0]?.field_date_range[0]?.end_value;
            return <DateText>{createRangeDate(startDate, endDate)}</DateText>;
        } else if (
            (field_event[0]?.field_event_date?.length > 0 || field_event[0]?.field_date_range?.length > 0) &&
            field_event[0]?.field_show_end_date?.length > 0
        ) {
            const date =
                field_event[0]?.field_event_date?.length > 0
                    ? field_event[0]?.field_event_date[0]?.value
                    : field_event[0]?.field_date_range[0]?.value;
            return <DateText>{createDate(date)}</DateText>;
        } else if (forceRender && field_event[0]?.created) {
            return (
                <DateText isNews={field_event[0]?.type !== NEWS_PARAGRAPH}>
                    {createDate(field_event[0]?.created * 1000)}
                </DateText>
            );
        }
        return '';
    };

    const displayNewsItems = (item: any, index: number): ReactNode => (
        <ContentBox
            isShowDate={
                item.field_event[0]?.field_event_date?.length > 0 &&
                item.field_event[0]?.field_hide_date[0]?.value !== '1'
            }
            key={index}
        >
            {displayDate(item.field_event)}
            <NewsContent>
                <Image
                    src={
                        item.field_event[0]?.field_image?.length > 0 &&
                        urlForImage(item.field_event[0]?.field_image[0]?.url)
                    }
                />
                <TextContent>
                    <Title>
                        {item.field_event[0]?.field_event_link?.length > 0 ? (
                            <a href={item.field_event[0].field_event_link[0]?.uri} target="_blank">
                                {item.field_event[0]?.title}
                            </a>
                        ) : item.field_event[0].node_url?.length > 0 ? (
                            <Link to={prepareSiteRedirection(language, item.field_event[0].node_url)}>
                                {item.field_event[0]?.title}
                            </Link>
                        ) : (
                            item.field_event[0]?.title
                        )}
                    </Title>
                    {item.field_event[0]?.field_location?.length > 0 && (
                        <EventLocation>{item.field_event[0].field_location[0]?.value}</EventLocation>
                    )}
                    {item.field_event[0]?.field_event_text?.length > 0 && (
                        <Description
                            dangerouslySetInnerHTML={{ __html: item.field_event[0]?.field_event_text[0]?.summary }}
                        ></Description>
                    )}
                </TextContent>
            </NewsContent>
        </ContentBox>
    );

    const displayItems = (): ReactNode =>
        filteredData?.map((item: any, index: number) => {
            if (index < currentLength) {
                if (item?.type === NEWS_PARAGRAPH && item?.field_event?.length > 0) {
                    return displayNewsItems(item, index);
                } else if (item?.type === ADVANCED_TEXT_PARAGRAPH) {
                    return displayAdvancedTextItems(item, index);
                } else {
                    return null;
                }
            }
        });

    const displayOtherParagraphType = (): ReactNode =>
        filteredData
            ?.filter((item) => item?.type !== NEWS_PARAGRAPH && item?.type !== ADVANCED_TEXT_PARAGRAPH)
            ?.map((item: any, index: number) => checkParagraphType(item.type, item, item.type + index));

    return (
        <Container>
            <ContentContainer>{filteredData?.length > 0 && displayItems()}</ContentContainer>
            {currentLength < filteredData?.length && (
                <Button onClick={() => setCurrentLength((currentLength) => currentLength + 10)}>
                    {t('furtherArticles')}
                </Button>
            )}
            {displayOtherParagraphType()}
        </Container>
    );
};

export default News;
