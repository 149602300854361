import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
} from '../constants/languageCode';
import {
    UPS_CODE_DE,
    UPS_CODE_DE_AT,
    UPS_CODE_EN,
    UPS_CODE_EN_AU,
    UPS_CODE_EN_IN,
    UPS_CODE_EN_SG,
    UPS_CODE_EN_UK,
    UPS_CODE_EN_US,
    UPS_CODE_ES,
    UPS_CODE_ES_ES,
    UPS_CODE_FR,
    UPS_CODE_FR_BE,
    UPS_CODE_FR_FR,
    UPS_CODE_IT,
    UPS_CODE_NL_BE,
    UPS_CODE_NL_NL,
    UPS_CODE_ZH_HANS,
} from '../constants/upsLangcodes';

export const getUpsLangcode = (language: string): string => {
    switch (language) {
        case DE:
            return UPS_CODE_DE;
        case DE_AT:
            return UPS_CODE_DE_AT;
        case EN_AU:
            return UPS_CODE_EN_AU;
        case EN_IN:
            return UPS_CODE_EN_IN;
        case EN_SG:
            return UPS_CODE_EN_SG;
        case EN_UK:
            return UPS_CODE_EN_UK;
        case EN_US:
            return UPS_CODE_EN_US;
        case ES_ES:
            return UPS_CODE_ES_ES;
        case FR_FR:
            return UPS_CODE_FR_FR;
        case IT:
            return UPS_CODE_IT;
        case NL_NL:
            return UPS_CODE_NL_NL;
        case NL_BE:
            return UPS_CODE_NL_BE;
        case ZH_HANS:
            return UPS_CODE_ZH_HANS;
        case EN:
            return UPS_CODE_EN;
        case FR:
            return UPS_CODE_FR;
        case FR_BE:
            return UPS_CODE_FR_BE;
        case ES_US:
            return UPS_CODE_EN_US;
        case ES:
            return UPS_CODE_ES;
        default:
            return UPS_CODE_DE;
    }
};
