import React, { FC, ReactNode, useState } from 'react';
import useBrowserWidth from '../../../../hooks/useBrowserWidth';
import { ShowcaseGroupItemInterface } from '../../../../interfaces/page';
import { TABLET_SMALL_SCREEN_SIZE } from '../../../../styles/MediaQuery';
import {
    Container,
    ContentWrapper,
    ItemNumber,
    ItemTitle,
    ListItem,
    ListWrapper,
    MobileContainer,
} from './ShowcaseGroupStyle';
import ShowcaseItem from './ShowcaseItem/ShowcaseItem';

export interface ShowcaseGroupProps {
    showcaseGroupData: Array<ShowcaseGroupItemInterface>;
}

const ShowcaseGroup: FC<ShowcaseGroupProps> = ({ showcaseGroupData }) => {
    const [activeItem, setActiveItem] = useState(showcaseGroupData[0]?.field_title);
    const currentBrowserWidth = useBrowserWidth();

    const displayListItems = (): ReactNode =>
        showcaseGroupData
            ?.filter((item: any) => item?.field_title !== null && item)
            ?.map((item: any, index: number) => (
                <ListItem
                    onClick={() => setActiveItem(item.field_title)}
                    key={item.field_title}
                    isActive={activeItem === item.field_title}
                >
                    <ItemTitle>
                        <ItemNumber>{index + 1}</ItemNumber>
                        {item.field_title}
                    </ItemTitle>
                </ListItem>
            ));

    const displayItemContent = (): ReactNode =>
        showcaseGroupData
            ?.filter((item: any) => item?.field_title !== null && item)
            ?.map((item: any, index: number) => <ShowcaseItem key={index} data={item} activeItem={activeItem} />);

    const displayMobileView = (): ReactNode =>
        showcaseGroupData
            ?.filter((item: any) => item?.field_title !== null && item)
            ?.map((item: any, index: number) => (
                <MobileContainer key={item.field_title}>
                    <ListItem
                        onClick={() => setActiveItem(item.field_title)}
                        isActive={activeItem === item.field_title}
                    >
                        <ItemTitle>
                            <ItemNumber>{index + 1}</ItemNumber>
                            {item.field_title}
                        </ItemTitle>
                    </ListItem>
                    <ContentWrapper isActive={item.field_title === activeItem}>
                        <ShowcaseItem data={item} activeItem={activeItem} />
                    </ContentWrapper>
                </MobileContainer>
            ));

    const isMobileView = (): ReactNode => {
        if (!currentBrowserWidth) {
            return '';
        } else if (currentBrowserWidth <= TABLET_SMALL_SCREEN_SIZE) {
            return displayMobileView();
        } else {
            return (
                <>
                    <ListWrapper>{displayListItems()}</ListWrapper>
                    <ContentWrapper>{displayItemContent()}</ContentWrapper>
                </>
            );
        }
    };

    return <Container>{isMobileView()}</Container>;
};

export default ShowcaseGroup;
