import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { greenPrint } from '../../styles/Variables';
import { LoaderWrapper } from './LoadingSpinnerStyle';

const LoadingSpinner = ({ isLoading }) => (
    <LoaderWrapper>
        <ClipLoader color={greenPrint} loading={isLoading} size={20} />
    </LoaderWrapper>
);

export default LoadingSpinner;
