import styled from 'styled-components';
import { deviceMax } from '../../../styles/MediaQuery';
import {
    greyBorder,
    greyLight,
    lightGreyButton,
    lightGraishButton,
    darkGreen,
    lightGray,
    white,
} from '../../../styles/Variables';
import { Link } from 'gatsby';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';

interface SliderType {
    readonly height: number;
}

export const Slider = styled.ul<SliderType>`
    width: 942px;
    display: flex;
    list-style: none;
    padding-left: 0;
    height: ${(props) => `${props.height}px`};
    overflow: hidden;
    position: relative;
    margin-block: 0;

    @media ${deviceMax.laptop} {
        width: 624px;
    }

    @media ${deviceMax.tablet} {
        width: 302px;
    }
`;

interface CardType {
    readonly position: number;
    readonly lastActiveDesktop: number;
    readonly lastActiveTablet: number;
    readonly lastActiveMobile: number;
}

export const Card = styled.li<CardType>`
    height: calc(100% - 2px);
    width: 300px;
    position: absolute;
    top: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    border: 1px solid ${greyBorder};
    left: ${(props) => {
        const photosToDisplay = 3;
        for (let i = 0; i < photosToDisplay; i++) {
            if (props.position === props.lastActiveDesktop - i) {
                return `${320 * (photosToDisplay - 1 - i)}px`;
            }
        }
        if (props.position < props.lastActiveDesktop - 2) {
            return '-100%';
        } else {
            return '105%';
        }
    }};

    @media ${deviceMax.laptop} {
        left: ${(props) => {
            const photosToDisplay = 2;
            for (let i = 0; i < photosToDisplay; i++) {
                if (props.position === props.lastActiveTablet - i) {
                    return `${320 * (photosToDisplay - 1 - i)}px`;
                }
            }
            if (props.position < props.lastActiveTablet - 1) {
                return '-100%';
            } else {
                return '105%';
            }
        }};
    }

    @media ${deviceMax.tablet} {
        left: ${(props) => {
            if (props.position === props.lastActiveMobile) {
                return '0px';
            } else if (props.position < props.lastActiveMobile) {
                return '-100%';
            } else {
                return '105%';
            }
        }};
    }
`;

export const CardTitle = styled.h2`
    font-size: 0.9375rem;
    text-transform: uppercase;
    padding: 1.5rem 0.75rem 1.25rem 1.25rem;
    background-color: ${greyLight};
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 1.5rem 0.75rem 2.1875rem 1.25rem;
`;

export const CardImage = styled.img`
    width: 100%;
    height: 153px;
`;

export const CardSubtitle = styled.h3`
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: Arial, Helvetica;
    padding: 0.75rem 1.25rem 0 1.25rem;
    margin: 0;
    line-height: 1.25rem;
`;

export const Text = styled.p`
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-family: Arial, Helvetica;
    padding: 0rem 1.25rem 1.375rem 1.25rem;
    margin: 0;
`;

export const CardLink = styled(Link)`
    margin: 0rem 1.25rem 1.375rem 1.25rem;
    border: 1px solid ${lightGray};
    padding: 0.75rem 1.875rem;
    border-radius: 5px;
    cursor: pointer;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    text-decoration: none;
    font-family: Arial, Helvetica;
    font-size: 0.875rem;
    color: ${darkGreen};

    &:hover {
        background: ${lightGraishButton};
    }
`;

export const CardLinkWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
`;

const SliderNavigationArrowWrapper = styled.div`
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
    position: absolute;
    top: 37%;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.3);
`;

interface ArrowInterface {
    readonly numberOfCards: number;
}

export const LeftArrowWrapper = styled(SliderNavigationArrowWrapper)<ArrowInterface>`
    display: ${(props) => (props.numberOfCards <= 3 ? 'none' : 'flex')};
    left: 0;

    @media ${deviceMax.laptop} {
        display: ${(props) => (props.numberOfCards <= 2 ? 'none' : 'flex')};
    }

    @media ${deviceMax.tablet} {
        display: ${(props) => (props.numberOfCards <= 1 ? 'none' : 'flex')};
    }
`;

export const RightArrowWrapper = styled(SliderNavigationArrowWrapper)<ArrowInterface>`
    display: ${(props) => (props.numberOfCards <= 3 ? 'none' : 'flex')};
    right: 0;

    @media ${deviceMax.laptop} {
        display: ${(props) => (props.numberOfCards <= 2 ? 'none' : 'flex')};
    }

    @media ${deviceMax.tablet} {
        display: ${(props) => (props.numberOfCards <= 1 ? 'none' : 'flex')};
    }
`;

export const LeftArrow = styled(BsChevronLeft)`
    fill: ${white};
    font-size: 1.5em;
`;

export const RightArrow = styled(BsChevronRight)`
    fill: ${white};
    font-size: 1.5em;
`;
